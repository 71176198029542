export default [
  {
    path: "/employee/bookings",
    children: [
      {
        path: "/employee/bookings/",
        name: "booking-system-index",
        component: () => import("@/views/roles/employee/bookings/Index.vue"),
        meta: {
          pageTitle: "Reservar sala de juntas",
          breadcrumbs: ["Empleados", "Sala de Juntas"],
        },
      },
    ],
  },
];
