import { AxiosResponse } from "axios";
import { GenericError } from "@/core/services/GenericError";

const defaultErrorMessage = "Tenemos problemas para conectar con el servidor";

class ServiceHandler {
  public static checkSuccessEmpty() {
    return (response: AxiosResponse<null>): Promise<boolean> => {
      if (!ServiceHandler.isSuccess(response.status)) {
        return Promise.reject(
          ServiceHandler.getError({
            statusCode: response.status,
          })
        );
      }

      return Promise.resolve(true);
    };
  }

  public static checkSuccess<T>(): (response: AxiosResponse<T>) => Promise<T> {
    return async (response: AxiosResponse<T>): Promise<T> => {
      if (!ServiceHandler.isSuccess(response.status)) {
        return Promise.reject(
          ServiceHandler.getError({
            statusCode: response.status,
          })
        );
      }

      return response.data;
    };
  }

  public static catchError<T extends GenericError = GenericError>() {
    return (error) => {
      if (error.response) {
        const genericError = ServiceHandler.getError(error.response.data);
        const errorData = error.response.data as T;
        errorData.message = genericError.message;

        return Promise.reject(errorData);
      }

      const errorData = ServiceHandler.getError({
        statusCode: error.status,
        message: error.message,
      });

      return Promise.reject(errorData);
    };
  }

  private static isSuccess(status: number): boolean {
    return status >= 200 && status < 300;
  }

  private static getError(error): GenericError {
    const messages: string[] = Array.isArray(error.message)
      ? error.message
      : [error.message ?? defaultErrorMessage];

    if (messages.length === 0) {
      messages.push(defaultErrorMessage);
    }

    const errorData: GenericError = {
      statusCode: error.status ?? 500,
      message: messages,
      error: error.error ?? messages[0],
    };

    return errorData;
  }
}

export default ServiceHandler;
