import { Api } from "@/core/services/Api";
import axios from "axios";
import { Services } from "../api.config";
import ServiceHandler from "@/core/services/ServiceHandler";
import { AuthModel } from "@/models/auth/auth.model";
import { UserModel } from "@/models/user/user.model";
import { authAxios, companyAxios } from "@/core/services/CustomAxios";
import { EmployeeModel } from "@/models/employees/employee.model";

class AuthService {
  public async login(login: string, password: string): Promise<AuthModel> {
    try {
      const serviceUrl = Api.getCentralServiceUrl(Services.LOGIN);
      return axios
        .post(serviceUrl, {
          login: login,
          data: login,
          platform: "mail",
          password: password,
        })
        .then(ServiceHandler.checkSuccess<AuthModel>())
        .catch(ServiceHandler.catchError());
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async forgotPassword(login: string): Promise<void> {
    try {
      const serviceUrl = Api.getCentralServiceUrl(Services.FORGOT_PASSWORD);
      await axios
        .post(serviceUrl, {
          login,
        })
        .then(ServiceHandler.checkSuccessEmpty())
        .catch(ServiceHandler.catchError());
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async validateResetCode(code: string): Promise<void> {
    try {
      const serviceUrl = Api.getCentralServiceUrl(
        Services.RESET_CODE_VALIDATE,
        { code }
      );

      await axios
        .get(serviceUrl)
        .then(ServiceHandler.checkSuccessEmpty())
        .catch(ServiceHandler.catchError());
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async resetPassword(
    resetCode: string,
    password: string
  ): Promise<void> {
    try {
      const serviceUrl = Api.getCentralServiceUrl(Services.RESET_PASSWORD);

      await axios
        .post(serviceUrl, {
          resetCode,
          password,
        })
        .then(ServiceHandler.checkSuccessEmpty())
        .catch(ServiceHandler.catchError());
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async getUserInfo(): Promise<UserModel> {
    try {
      const serviceUrl = Api.getCentralServiceUrl(Services.GET_USER_INFO);
      return authAxios
        .get(serviceUrl)
        .then(ServiceHandler.checkSuccess<UserModel>())
        .catch(ServiceHandler.catchError());
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async getEmployeeInfo(): Promise<EmployeeModel> {
    try {
      const serviceUrl = Api.getCompanyServiceUrl(Services.GET_EMPLOYEE);
      return companyAxios
        .get(serviceUrl)
        .then(ServiceHandler.checkSuccess<EmployeeModel>())
        .catch(ServiceHandler.catchError());
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export const authService = new AuthService();
