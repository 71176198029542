
import {
  computed,
  defineComponent,
  nextTick,
  onBeforeMount,
  onMounted,
  ref,
  watch,
} from "vue";
import { useStore } from "vuex";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { themeMode } from "@/core/helpers/config";
import { initializeComponents } from "@/core/plugins/keenthemes";
import { Nullable } from "./core/helpers/customtypes";
import { CompanyModel } from "./models/companies/company.model";

export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();
    const currentCompany = computed<Nullable<CompanyModel>>(
      () => store.getters.currentCompany
    );

    const initialColor = ref<string>();

    function hexToRgb(hex): Nullable<{
      r: Nullable<number>;
      g: Nullable<number>;
      b: Nullable<number>;
    }> {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
    }

    const setFavicon = (company: Nullable<CompanyModel>) => {
      const favicon = document.querySelector(
        'link[rel="icon"]'
      ) as HTMLLinkElement;
      if (!favicon) return;

      if (!company) {
        favicon.href = "/favicon.ico";
        return;
      }

      favicon.href = company.iconUrl ?? "/favicon.ico";
    };

    const setStyle = (company: Nullable<CompanyModel>) => {
      const root = document.querySelector<HTMLElement>(":root");
      if (!root) return;

      // TODO: Check pending variables

      const variableList = [
        "--kt-primary",
        "--kt-primary-active",
        "--kt-text-primary",
        "--kt-link-color",
        "--kt-component-active-bg",
        "--kt-component-hover-color",
        "--kt-component-checked-bg",
        "--kt-btn-link-color",
        "--kt-form-check-input-checked-bg-color",
        "--kt-form-check-input-checked-bg-color-solid",
        "--kt-form-check-input-indeterminate-bg-color",
        "--kt-form-check-input-indeterminate-border-color",
        "--kt-nav-pills-link-active-bg",
        "--kt-dropdown-link-active-bg",
        "--kt-pagination-focus-color",
        "--kt-pagination-active-bg",
        "--kt-accordion-button-active-color",
        "--kt-accordion-icon-active-color",
        "--kt-pagination-hover-color",
        "--el-color-primary",
      ];

      let primaryColor = company?.mainColor ?? initialColor.value ?? "";

      variableList.forEach((variable) => {
        root?.style.setProperty(variable, primaryColor);
      });

      const variablePrimaryLightList = ["--kt-primary-light"];

      variablePrimaryLightList.forEach((variable) => {
        root?.style.setProperty(variable, `${primaryColor}4d`);
      });

      const variableRgbList = ["--bs-primary-rgb"];

      variableRgbList.forEach((variable) => {
        const rgb = hexToRgb(primaryColor);
        if (!rgb) return;

        root?.style.setProperty(variable, `${rgb.r}, ${rgb.g}, ${rgb.b}`);
      });
    };

    watch(
      () => currentCompany.value,
      () => {
        setStyle(currentCompany.value);
        setFavicon(currentCompany.value);
      }
    );

    onBeforeMount(() => {
      store.dispatch(Actions.INIT_COMPANY);
    });

    onMounted(() => {
      var root = document.querySelector<HTMLElement>(":root");
      initialColor.value = root?.style.getPropertyValue("--kt-primary");
      /**
       * Overrides the layout config using saved data from localStorage
       * remove this to use static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      /**
       *  Sets a mode from configuration
       */
      store.dispatch(Actions.SET_THEME_MODE_ACTION, themeMode.value);

      nextTick(() => {
        initializeComponents();

        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      });

      setStyle(currentCompany.value);
      setFavicon(currentCompany.value);
    });
  },
});
