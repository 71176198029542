
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "copy-button",
  props: {
    icon: { type: String, required: false },
    tooltip: { type: String, required: false },
    data: { type: String, required: true },
  },
  emits: ["action"],
  setup(props) {
    const onAction = () => {
      navigator.clipboard.writeText(props.data);
    };

    return { onAction };
  },
});
