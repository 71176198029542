export default [
  {
    path: "/superadmin/subcompanies",
    children: [
      {
        path: "/superadmin/subcompanies/",
        name: "subcompanies-index",
        component: () =>
          import("@/views/roles/super-admin/subcompanies/Index.vue"),
        meta: {
          pageTitle: "Empresas",
          breadcrumbs: ["Admin", "Empresas"],
        },
      },
      {
        path: "/superadmin/subcompanies/create",
        name: "subcompanies-create",
        component: () =>
          import("@/views/roles/super-admin/subcompanies/Create.vue"),
        meta: {
          pageTitle: "Empresas",
          breadcrumbs: ["Admin", "Empresas", "Create"],
        },
      },
      {
        path: "/superadmin/subcompanies/edit/:id?",
        name: "subcompanies-edit",
        component: () =>
          import("@/views/roles/super-admin/subcompanies/Edit.vue"),
        meta: {
          pageTitle: "Empresas",
          breadcrumbs: ["Admin", "Empresas", "Edit"],
        },
      },
    ],
  },
];
