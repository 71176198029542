export default [
  {
    path: "/superadmin/help-channels",
    children: [
      {
        path: "/superadmin/help-channels/",
        name: "help-channels-index",
        component: () =>
          import("@/views/roles/super-admin/help/help-channels/Index.vue"),
        meta: {
          pageTitle: "Canales de ayuda",
          breadcrumbs: ["Admin", "Canales de ayuda"],
        },
      },
      {
        path: "/superadmin/help-channels/create",
        name: "help-channels-create",
        component: () =>
          import("@/views/roles/super-admin/help/help-channels/Create.vue"),
        meta: {
          pageTitle: "Canales de ayuda",
          breadcrumbs: ["Admin", "Canales de ayuda", "Create"],
        },
      },
      {
        path: "/superadmin/help-channels/edit/:id?",
        name: "help-channels-edit",
        component: () =>
          import("@/views/roles/super-admin/help/help-channels/Edit.vue"),
        meta: {
          pageTitle: "Canales de ayuda",
          breadcrumbs: ["Admin", "Canales de ayuda", "Edit"],
        },
      },
    ],
  },
  {
    path: "/superadmin/faq-categories",
    children: [
      {
        path: "/superadmin/faq-categories/",
        name: "faq-categories-index",
        component: () =>
          import("@/views/roles/super-admin/help/faq-categories/Index.vue"),
        meta: {
          pageTitle: "Categorías de FAQ",
          breadcrumbs: ["Admin", "FAQ", "Categorías"],
        },
      },
      {
        path: "/superadmin/faq-categories/create",
        name: "faq-categories-create",
        component: () =>
          import("@/views/roles/super-admin/help/faq-categories/Create.vue"),
        meta: {
          pageTitle: "Categorías de FAQ",
          breadcrumbs: ["Admin", "FAQ", "Categorías", "Create"],
        },
      },
      {
        path: "/superadmin/faq-categories/edit/:id?",
        name: "faq-categories-edit",
        component: () =>
          import("@/views/roles/super-admin/help/faq-categories/Edit.vue"),
        meta: {
          pageTitle: "Categorías de FAQ",
          breadcrumbs: ["Admin", "FAQ", "Categorías", "Edit"],
        },
      },
    ],
  },
  {
    path: "/superadmin/faq",
    children: [
      {
        path: "/superadmin/faq/",
        name: "faq-index",
        component: () => import("@/views/roles/super-admin/help/faq/Index.vue"),
        meta: {
          pageTitle: "FAQ",
          breadcrumbs: ["Admin", "FAQ"],
        },
      },
      {
        path: "/superadmin/faq/create",
        name: "faq-create",
        component: () =>
          import("@/views/roles/super-admin/help/faq/Create.vue"),
        meta: {
          pageTitle: "FAQ",
          breadcrumbs: ["Admin", "FAQ", "Create"],
        },
      },
      {
        path: "/superadmin/faq/edit/:id?",
        name: "faq-edit",
        component: () => import("@/views/roles/super-admin/help/faq/Edit.vue"),
        meta: {
          pageTitle: "FAQ",
          breadcrumbs: ["Admin", "FAQ", "Edit"],
        },
      },
    ],
  },
];
