export default [
  {
    path: "/master/restaurants",
    children: [
      {
        path: "/master/restaurants/",
        name: "restaurants-master-index",
        component: () => import("@/views/roles/master/restaurants/Index.vue"),
        meta: {
          pageTitle: "Restaurantes",
          breadcrumbs: ["Admin", "Restaurantes"],
        },
      },
      {
        path: "/master/restaurants/create",
        name: "restaurants-master-create",
        component: () => import("@/views/roles/master/restaurants/Create.vue"),
        meta: {
          pageTitle: "Restaurantes",
          breadcrumbs: ["Admin", "Restaurantes", "Create"],
        },
      },
      {
        path: "/master/restaurants/edit/:id?",
        name: "restaurants-master-edit",
        component: () => import("@/views/roles/master/restaurants/Edit.vue"),
        meta: {
          pageTitle: "Restaurantes",
          breadcrumbs: ["Admin", "Restaurantes", "Edit"],
        },
      },
      {
        path: "/master/restaurants/details/:id?",
        name: "restaurants-master-details",
        component: () => import("@/views/roles/master/restaurants/Details.vue"),
        meta: {
          pageTitle: "Restaurantes",
          breadcrumbs: ["Admin", "Restaurantes", "Detalles"],
        },
      },
    ],
  },
  {
    path: "/master/redemptions",
    children: [
      {
        path: "/master/redemptions/",
        name: "maaster-redemptions-index",
        component: () =>
          import("@/views/roles/master/redemptions/redemptions/Index.vue"),
        meta: {
          pageTitle: "Redenciones",
          breadcrumbs: ["Admin", "Redenciones"],
        },
      },
      {
        path: "/master/redemptions/details/:id?",
        name: "maaster-redemptions-details",
        component: () =>
          import("@/views/roles/master/redemptions/redemptions/Detail.vue"),
        meta: {
          pageTitle: "Redenciones",
          breadcrumbs: ["Admin", "Redenciones"],
        },
      },
      {
        path: "/master/redemptions/cuts",
        name: "maaster-redemptions-cuts",
        component: () =>
          import("@/views/roles/master/redemptions/redemptions-cuts/Index.vue"),
        meta: {
          pageTitle: "Redenciones",
          breadcrumbs: ["Admin", "Redenciones", "Cortes"],
        },
      },
      {
        path: "/master/redemptions/cuts/details/:id?",
        name: "maaster-redemptions-cuts-details",
        component: () =>
          import(
            "@/views/roles/master/redemptions/redemptions-cuts/Detail.vue"
          ),
        meta: {
          pageTitle: "Redenciones",
          breadcrumbs: ["Admin", "Redenciones", "Cortes"],
        },
      },
    ],
  },
];
