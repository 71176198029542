export default [
  {
    path: "/superadmin/internal-benefits/categories",
    children: [
      {
        path: "/superadmin/internal-benefits/categories",
        name: "internal-benefit-categories-index",
        component: () =>
          import(
            "@/views/roles/super-admin/internal-benefits/categories/Index.vue"
          ),
        meta: {
          pageTitle: "Categorías de beneficios internos",
          breadcrumbs: ["Admin", "Beneficios Internos", "Categorías"],
        },
      },
      {
        path: "/superadmin/internal-benefits/categories/create",
        name: "internal-benefit-categories-create",
        component: () =>
          import(
            "@/views/roles/super-admin/internal-benefits/categories/Create.vue"
          ),
        meta: {
          pageTitle: "Crear categoría",
          breadcrumbs: ["Admin", "Beneficios Internos", "Categorías", "Crear"],
        },
      },
      {
        path: "/superadmin/internal-benefits/categories/edit/:id?",
        name: "internal-benefit-categories-edit",
        component: () =>
          import(
            "@/views/roles/super-admin/internal-benefits/categories/Edit.vue"
          ),
        meta: {
          pageTitle: "Editar categoría",
          breadcrumbs: ["Admin", "Beneficios Internos", "Categorías", "Editar"],
        },
      },
    ],
  },
];
