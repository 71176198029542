export default [
  {
    path: "/master/users",
    children: [
      {
        path: "/master/users/",
        name: "users-index",
        component: () => import("@/views/roles/master/admin/users/Index.vue"),
        meta: {
          pageTitle: "Usuarios Nuup",
          breadcrumbs: ["Admin", "Users"],
        },
      },
      {
        path: "/master/users/create",
        name: "users-create",
        component: () => import("@/views/roles/master/admin/users/Create.vue"),
        meta: {
          pageTitle: "Usuarios Nuup",
          breadcrumbs: ["Admin", "Users", "Create"],
        },
      },
      {
        path: "/master/users/edit/:id?",
        name: "users-edit",
        component: () => import("@/views/roles/master/admin/users/Edit.vue"),
        meta: {
          pageTitle: "Usuarios Nuup",
          breadcrumbs: ["Admin", "Users", "Edit"],
        },
      },
    ],
  },
];
