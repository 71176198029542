
import { defineComponent } from "@vue/runtime-core";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "confirm-action-button",
  props: {
    tooltip: { type: String, required: false },
    confirmTitle: { type: String, required: false },
    confirmMessage: { type: String, required: false },

    icon: { type: String, required: true },
    btnClass: { type: String },
  },
  emits: ["action"],
  setup(props, { emit }) {
    const onAction = () => {
      Swal.fire({
        title: props.confirmTitle ?? "Deseas continuar?",
        text:
          props.confirmMessage ?? "¿Estas seguro de continuar con esta acción?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Sí, continuar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          emit("action");
        }
      });
    };

    return { onAction };
  },
});
