import ConfigRoutes from "./modules/config";
import CompaniesRoutes from "./modules/companies";
import RedemptionsRoutes from "./modules/redemptions";

export default [
  {
    path: "/restaurant",
    meta: {
      middleware: "auth",
    },
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    children: [...ConfigRoutes, ...CompaniesRoutes, ...RedemptionsRoutes],
  },
];
