import { ModuleEnum, ModulesMap } from "@/models/modules/modules.model";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Mutations, Actions } from "../enums/StoreEnums";
import { Nullable } from "@/core/helpers/customtypes";
import { modulesService } from "@/services/modules/modules.service";
import router from "@/router";
import { IntegrationTypeEnum } from "@/models/integrations/integration.model";
import { integrationCompaniesService } from "@/services/integrations/integration-companies.service";

export interface ModulesInfo {
  config: ModulesMap;
  pages: { route: string; modules: ModuleEnum[] }[];
}

@Module
export default class ModulesModule extends VuexModule implements ModulesInfo {
  config: ModulesMap = {};
  pages: { route: string; modules: ModuleEnum[] }[] = [
    {
      route: "home-eployee-index",
      modules: [ModuleEnum.NEWS, ModuleEnum.RELEASES, ModuleEnum.POSTS],
    },
    {
      route: "meals-index",
      modules: [ModuleEnum.MEALS],
    },
    {
      route: "benefits-eployee-index",
      modules: [ModuleEnum.BENEFITS],
    },
    {
      route: "calendar-eployee-index",
      modules: [ModuleEnum.CALENDAR],
    },
    {
      route: "surveys-employee-index",
      modules: [ModuleEnum.SURVEYS],
    },
    {
      route: "events-eployee-index",
      modules: [ModuleEnum.EVENTS],
    },
    {
      route: "teambuilding-eployee-index",
      modules: [ModuleEnum.TEAM_BUILDING],
    },
    {
      route: "help-index",
      modules: [ModuleEnum.FAQS, ModuleEnum.HELP_CHANNEL, ModuleEnum.REPORTS],
    },
  ];

  get currentModuleConfig(): Nullable<ModulesMap> {
    return this.config;
  }

  @Mutation
  [Mutations.SET_MODULES_CONFIG](config: ModulesMap) {
    // For test
    // config = {
    //   moduleMealsEnabled: false,
    //   moduleCustomAssetsInCentralEnabled: true,
    //   moduleWhiteLabelEnabled: true,
    //   moduleReleasesEnabled: false,
    //   moduleHelpChannelsEnabled: false,
    //   moduleNewsEnabled: true,
    //   moduleFAQsEnabled: false,
    //   modulePostsEnabled: false,
    //   moduleEventsEnabled: false,
    //   moduleBenefitsEnabled: false,
    //   moduleAnonymousReportsEnabled: false,
    //   moduleTeamBuildingEnabled: true,
    //   moduleSurveysEnabled: false,
    //   moduleCalendarEnabled: false,
    // };

    this.config = config;
  }

  @Action
  async [Actions.UPDATE_MODULES_CONFIG]() {
    try {
      const config = await modulesService.getConfig();
      const integrations =
        await integrationCompaniesService.getActiveForCompany();

      config[ModuleEnum.EXTERNAL_ATTENDANCES] = !!integrations.find(
        (integration) =>
          integration.type?.id === IntegrationTypeEnum.ATTENDANCES
      );

      config[ModuleEnum.EXTERNAL_HR] = !!integrations.find(
        (integration) => integration.type?.id === IntegrationTypeEnum.HR
      );

      this.context.commit(Mutations.SET_MODULES_CONFIG, config);
    } catch (e) {
      // ignore
    }
  }

  @Action
  [Actions.REDIRECT_DEFAULT_EMPLOYEE]() {
    const getConfig = () => {
      return Object.keys(this.config).length > 0
        ? Promise.resolve(this.config)
        : modulesService.getConfig();
    };

    const isModuleEnabled = (module: ModuleEnum) => {
      return this.config?.[module] ?? true;
    };

    const areModulesEnabled = (modules: ModuleEnum[]) => {
      if (!modules) return true;
      return modules.some((module) => isModuleEnabled(module));
    };

    getConfig()
      .then((config) => {
        this.context.commit(Mutations.SET_MODULES_CONFIG, config);
        for (const page of this.pages) {
          if (areModulesEnabled(page.modules)) {
            return router.push({ name: page.route });
          }
        }

        router.push({ name: "profile-index" });
      })
      .catch(() => {
        // ignored
        router.push({ name: "profile-index" });
      });
  }
}
