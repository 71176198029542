import { Nullable } from "@/core/helpers/customtypes";
import { Api } from "@/core/services/Api";
import { companyAxios } from "@/core/services/CustomAxios";
import ServiceHandler from "@/core/services/ServiceHandler";
import { Services } from "../api.config";

import {
  CompanyIntegrationDto,
  IntegrationCompanyDto,
  IntegrationCompanyModel,
} from "@/models/integrations/integration-company.model";
import { IntegrationModel } from "@/models/integrations/integration.model";
import { CompanyModel } from "@/models/companies/company.model";

class IntegrationCompaniesService {
  public async getForIntegration(
    id: Nullable<string>
  ): Promise<CompanyModel[]> {
    try {
      const serviceUrl = Api.getCentralServiceUrl(
        Services.INTEGRATIONS_COMPANIES_LIST,
        {
          id,
        }
      );

      return await companyAxios
        .get(serviceUrl)
        .then(ServiceHandler.checkSuccess<CompanyModel[]>())
        .catch(ServiceHandler.catchError());
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async getForCompany(
    id: Nullable<string>
  ): Promise<IntegrationModel[]> {
    try {
      const serviceUrl = Api.getCentralServiceUrl(
        Services.COMPANIES_INTEGRATION_LIST,
        {
          id,
        }
      );

      return await companyAxios
        .get(serviceUrl)
        .then(ServiceHandler.checkSuccess<IntegrationModel[]>())
        .catch(ServiceHandler.catchError());
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async getActiveForCompany(): Promise<IntegrationModel[]> {
    try {
      const serviceUrl = Api.getCompanyServiceUrl(
        Services.INTEGRATIONS_LIST_ACTIVE
      );

      return await companyAxios
        .get(serviceUrl)
        .then(ServiceHandler.checkSuccess<IntegrationModel[]>())
        .catch(ServiceHandler.catchError());
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async updateForIntegration(
    id: Nullable<string>,
    data: Nullable<IntegrationCompanyDto>
  ): Promise<IntegrationCompanyModel[]> {
    try {
      const serviceUrl = Api.getCentralServiceUrl(
        Services.INTEGRATIONS_COMPANIES_UPDATE,
        {
          id,
        }
      );

      return await companyAxios
        .put(serviceUrl, data)
        .then(ServiceHandler.checkSuccess<IntegrationCompanyModel[]>())
        .catch(ServiceHandler.catchError());
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async setActiveCompany(
    id: Nullable<string>,
    companyId: Nullable<string>,
    isActive: boolean,
    executeInit: boolean
  ) {
    try {
      const serviceUrl = Api.getCentralServiceUrl(
        Services.INTEGRATIONS_COMPANIES_UPDATE_SINGLE,
        {
          id,
          companyId,
        }
      );

      return await companyAxios
        .put(serviceUrl, {
          isActive,
          executeInit,
        })
        .then(ServiceHandler.checkSuccess<IntegrationModel>())
        .catch(ServiceHandler.catchError());
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async updateForCompany(
    id: Nullable<string>,
    data: Nullable<CompanyIntegrationDto>
  ): Promise<void> {
    try {
      const serviceUrl = Api.getCentralServiceUrl(
        Services.COMPANIES_INTEGRATION_UPDATE,
        {
          id,
        }
      );

      await companyAxios
        .put(serviceUrl, data)
        .then(ServiceHandler.checkSuccess<IntegrationCompanyModel[]>())
        .catch(ServiceHandler.catchError());

      return;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async setActiveIntegration(
    id: Nullable<string>,
    integrationId: Nullable<string>,
    isActive: boolean,
    executeInit: boolean
  ) {
    try {
      const serviceUrl = Api.getCentralServiceUrl(
        Services.COMPANIES_INTEGRATION_UPDATE_SINGLE,
        {
          id,
          integrationId,
        }
      );

      return await companyAxios
        .put(serviceUrl, {
          isActive,
          executeInit,
        })
        .then(ServiceHandler.checkSuccess<IntegrationModel>())
        .catch(ServiceHandler.catchError());
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async setActiveIntegrationForCompany(
    id: Nullable<string>,
    isActive: boolean,
    executeInit: boolean
  ) {
    try {
      const serviceUrl = Api.getCompanyServiceUrl(
        Services.INTEGRATIONS_ACTIVE,
        {
          id,
        }
      );

      return await companyAxios
        .put(serviceUrl, {
          isActive,
          executeInit,
        })
        .then(ServiceHandler.checkSuccess<IntegrationModel>())
        .catch(ServiceHandler.catchError());
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async executeInit(id: Nullable<string>, companyId: Nullable<string>) {
    try {
      const serviceUrl = Api.getCentralServiceUrl(
        Services.INTEGRATIONS_COMPANIES_INIT,
        {
          id,
          companyId,
        }
      );

      return await companyAxios
        .post(serviceUrl)
        .then(ServiceHandler.checkSuccess<IntegrationModel>())
        .catch(ServiceHandler.catchError());
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async executeInitForCompany(id: Nullable<string>) {
    try {
      const serviceUrl = Api.getCentralServiceUrl(Services.INTEGRATIONS_INIT, {
        id,
      });

      return await companyAxios
        .post(serviceUrl)
        .then(ServiceHandler.checkSuccess<IntegrationModel>())
        .catch(ServiceHandler.catchError());
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export const integrationCompaniesService = new IntegrationCompaniesService();
