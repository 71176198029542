export enum Services {
  MODULES_CONFIG = "/companies/feature-flags/config",

  LOGIN = "/auth/login",
  FORGOT_PASSWORD = "/auth/password/forgot",
  RESET_CODE_VALIDATE = "/auth/password/reset/{code}",
  RESET_PASSWORD = "/auth/password/reset",
  GET_USER_INFO = "/auth/me",
  GET_EMPLOYEE = "/employees/me",

  CONFIG_GET = "/config",
  CONFIG_CREATE = "/config",

  USERS_LIST = "/users",
  USERS_GET = "/users/{id}",
  USERS_CREATE = "/users",
  USERS_UPDATE = "/users/{id}",
  USERS_DELETE = "/users/{id}",
  USERS_PROFILE_PICTURE = "/accounts/profilepicture",
  USERS_ACCOUNT_UPDATE = "/accounts",

  COMPANIES_LIST = "/companies",
  COMPANIES_GET = "/companies/{id}",
  COMPANIES_GET_BY_KEY = "/companies/key/{key}",
  COMPANIES_CREATE = "/companies",
  COMPANIES_UPDATE = "/companies/{id}",
  COMPANIES_DELETE = "/companies/{id}",

  COMPANIES_EXTRA_INFO_GET = "/companies/{id}/extra-info",
  COMPANIES_EXTRA_INFO_UPDATE = "/companies/{id}/extra-info",
  COMPANIES_EXTRA_INFO_DELETE = "/companies/{id}/extra-info",
  COMPANIES_EXTRA_INFO_ME = "/companies/extra-info",

  COMPANY_SUPERADMINS_LIST = "/companies/{companyId}/superadmins",
  COMPANY_SUPERADMINS_GET = "/companies/{companyId}/superadmins/{id}",
  COMPANY_SUPERADMINS_CREATE = "/companies/{companyId}/superadmins",
  COMPANY_SUPERADMINS_UPDATE = "/companies/{companyId}/superadmins/{id}",
  COMPANY_SUPERADMINS_DELETE = "/companies/{companyId}/superadmins/{id}",

  CATALOGUES_GENDER_LIST = "/catalogues/genderidentities",
  CATALOGUES_PRONOUN_LIST = "/catalgoues/pronouns",
  CATALOGUES_MARITALSTATUS_LIST = "/catalogues/maritalstatus",
  CATALOGUES_NATIONALITY_LIST = "/catalogues/nationalities",
  CATALOGUES_CLOTHINGSIZE_LIST = "/catalogues/clothingsizes",
  CATALOGUES_ZIPCODES_LIST = "/catalogues/zipcodes",
  CATALOGUES_EDUCATIONALLEVEL_LIST = "/catalogues/educationlevels",

  PARTNERS_LIST = "/partners",
  PARTNERS_LIST_PAGINATION = "/partners/paginate",
  PARTNERS_GET = "/partners/{id}",
  PARTNERS_CREATE = "/partners",
  PARTNERS_UPDATE = "/partners/{id}",
  PARTNERS_DELETE = "/partners/{id}",

  PARTNER_CATEGORIES_LIST = "/partners/categories",
  PARTNER_CATEGORIES_GET = "/partners/categories/{id}",
  PARTNER_CATEGORIES_CREATE = "/partners/categories",
  PARTNER_CATEGORIES_UPDATE = "/partners/categories/{id}",
  PARTNER_CATEGORIES_DELETE = "/partners/categories/{id}",

  AREAS_PERMISSIONS = "/areas/permissions",
  AREAS_LIST = "/areas",
  AREAS_EXPORT = "/areas/export",
  AREAS_GET = "/areas/{id}",
  AREAS_CREATE = "/areas",
  AREAS_CREATE_CHILDREN = "/areas/{id}/children",
  AREAS_UPDATE = "/areas/{id}",
  AREAS_DELETE = "/areas/{id}",
  AREAS_LEADER = "/areas/{id}/leader",

  TEAMS_PERMISSIONS = "/teams/permissions",
  TEAMS_LIST = "/teams",
  TEAMS_GET = "/teams/{id}",
  TEAMS_CREATE = "/teams",
  TEAMS_UPDATE = "/teams/{id}",
  TEAMS_DELETE = "/teams/{id}",
  TEAMS_LEADER = "/teams/{id}/leader",

  POSITIONS_LIST = "/positions",
  POSITIONS_EXPORT = "/positions/export",
  POSITIONS_GET = "/positions/{id}",
  POSITIONS_CREATE = "/positions",
  POSITIONS_UPDATE = "/positions/{id}",
  POSITIONS_DELETE = "/positions/{id}",

  WORK_MODALITIES_LIST = "/work-modalities",
  WORK_MODALITIES_GET = "/work-modalities/{id}",
  WORK_MODALITIES_CREATE = "/work-modalities",
  WORK_MODALITIES_UPDATE = "/work-modalities/{id}",
  WORK_MODALITIES_DELETE = "/work-modalities/{id}",

  EMPLOYEES_PERMISSIONS = "/employees/permissions",
  EMPLOYEES_LIST = "/employees",
  EMPLOYEES_PAGINATE = "/employees/paginate",
  EMPLOYEES_ME = "/employees/me",
  EMPLOYEES_ME_AREAS = "/employees/me/areas",
  EMPLOYEES_ME_TEAMS = "/employees/me/teams",
  EMPLOYEES_ME_AREAS_EMPLOYEES = "/employees/me/areas/employees",
  EMPLOYEES_IMPORT = "/employees/import",
  EMPLOYEES_GET = "/employees/{id}",
  EMPLOYEES_CREATE = "/employees",
  EMPLOYEES_UPDATE = "/employees/{id}",
  EMPLOYEES_UPDATE_PICTURE = "/employees/{id}/corporatepicture",
  EMPLOYEES_UPDATE_EXTRA_DATA = "/employees/me/extradata",
  EMPLOYEES_DELETE = "/employees/{id}",

  EMPLOYEES_AREAS_LIST = "/employees/{employeeId}/areas",
  EMPLOYEES_AREAS_CREATE = "/employees/{employeeId}/areas",
  EMPLOYEES_AREAS_UPDATE = "/employees/{employeeId}/areas/{areaId}",
  EMPLOYEES_AREAS_DELETE = "/employees/{employeeId}/areas/{areaId}",

  EMPLOYEES_TEAM_LIST = "/employees/{employeeId}/teams",
  EMPLOYEES_TEAM_CREATE = "/employees/{employeeId}/teams",
  EMPLOYEES_TEAM_DELETE = "/employees/{employeeId}/teams/{teamId}",

  EMPLOYEES_SCHEDULE_PERMISSIONS = "/employees/{employeeId}/schedules/permissions",
  EMPLOYEES_SCHEDULE_LIST = "/employees/{employeeId}/schedules",
  EMPLOYEES_SCHEDULE_SET = "/employees/{employeeId}/schedules",
  EMPLOYEES_SCHEDULE_UPDATE = "/employees/{employeeId}/schedules/{id}",
  EMPLOYEES_SCHEDULE_DELETE = "/employees/{employeeId}/schedules/{id}",

  EMPLOYEES_ADDRESSES_PERMISSIONS = "/employees/{employeeId}/addresses/permissions",
  EMPLOYEES_ADDRESSES_LIST = "/employees/{employeeId}/addresses",
  EMPLOYEES_ADDRESSES_GET = "/employees/{employeeId}/addresses/{id}",
  EMPLOYEES_ADDRESSES_CREATE = "/employees/{employeeId}/addresses",
  EMPLOYEES_ADDRESSES_UPDATE = "/employees/{employeeId}/addresses/{id}",
  EMPLOYEES_ADDRESSES_DELETE = "/employees/{employeeId}/addresses/{id}",

  HELP_CHANNEL_TYPE_LIST = "/helpchanneltypes",
  HELP_CHANNEL_TYPE_GET = "/helpchanneltypes/{id}",
  HELP_CHANNEL_TYPE_CREATE = "/helpchanneltypes",
  HELP_CHANNEL_TYPE_UPDATE = "/helpchanneltypes/{id}",
  HELP_CHANNEL_TYPE_DELETE = "/helpchanneltypes/{id}",

  HELP_CHANNEL_LIST = "/helpchannels",
  HELP_CHANNEL_GET = "/helpchannels/{id}",
  HELP_CHANNEL_CREATE = "/helpchannels",
  HELP_CHANNEL_UPDATE = "/helpchannels/{id}",
  HELP_CHANNEL_DELETE = "/helpchannels/{id}",

  FAQ_CATEGORIES_LIST = "/faqcategories",
  FAQ_CATEGORIES_GET = "/faqcategories/{id}",
  FAQ_CATEGORIES_CREATE = "/faqcategories",
  FAQ_CATEGORIES_UPDATE = "/faqcategories/{id}",
  FAQ_CATEGORIES_DELETE = "/faqcategories/{id}",

  FAQ_LIST = "/faq",
  FAQ_GET = "/faq/{id}",
  FAQ_CREATE = "/faq",
  FAQ_UPDATE = "/faq/{id}",
  FAQ_DELETE = "/faq/{id}",

  QUESTIONUSER_LIST = "/questionsusers",
  QUESTIONUSER_GET = "/questionsusers/{id}",
  QUESTIONUSER_CREATE = "/questionsusers",
  QUESTIONUSER_UPDATE = "/questionsusers/{id}",
  QUESTIONUSER_DELETE = "/questionsusers/{id}",

  NEWS_LIST = "/news",
  NEWS_PUBLIC = "/news/public",
  NEWS_GET = "/news/{id}",
  NEWS_CREATE = "/news",
  NEWS_UPDATE = "/news/{id}",
  NEWS_UPDATE_COVER_IMAGE = "/news/{id}/coverimage",
  NEWS_DELETE = "/news/{id}",

  NEWS_REACT = "/news/{newsId}/reactions",
  NEWS_UNREACT = "/news/{newsId}/reactions",

  NEWS_ATTACHMENT_LIST = "/news/{newsId}/attachments",
  NEWS_ATTACHMENT_CREATE = "/news/{newsId}/attachments",
  NEWS_ATTACHMENT_DELETE = "/news/{newsId}/attachments/{id}",

  NEWS_COMMENTS_LIST = "/news/{newsId}/comments",
  NEWS_COMMENTS_CREATE = "/news/{newsId}/comments",
  NEWS_COMMENTS_DELETE = "/news/{newsId}/comments/{id}",

  RELEASES_LIST = "/release",
  RELEASES_PUBLIC = "/release/public",
  RELEASES_GET = "/release/{id}",
  RELEASES_CREATE = "/release",
  RELEASES_UPDATE = "/release/{id}",
  RELEASES_UPDATE_COVER_IMAGE = "/release/{id}/coverimage",
  RELEASES_DELETE = "/release/{id}",

  RELEASES_REACT = "/release/{releaseId}/reactions",
  RELEASES_UNREACT = "/release/{releaseId}/reactions",

  RELEASES_ATTACHMENT_LIST = "/release/{releaseId}/attachments",
  RELEASES_ATTACHMENT_CREATE = "/release/{releaseId}/attachments",
  RELEASES_ATTACHMENT_DELETE = "/release/{releaseId}/attachments/{id}",

  RELEASES_COMMENTS_LIST = "/release/{releaseId}/comments",
  RELEASES_COMMENTS_CREATE = "/release/{releaseId}/comments",
  RELEASES_COMMENTS_DELETE = "/release/{releaseId}/comments/{id}",

  POSTS_LIST = "/post",
  POSTS_PUBLIC = "/post/public",
  POSTS_GET = "/post/{id}",
  POSTS_CREATE = "/post",
  POSTS_UPDATE = "/post/{id}",
  POSTS_UPDATE_COVER_IMAGE = "/post/{id}/coverimage",
  POSTS_DELETE = "/post/{id}",

  POSTS_REACT = "/post/{postId}/reactions",
  POSTS_UNREACT = "/post/{postId}/reactions",

  POSTS_ATTACHMENT_LIST = "/post/{postId}/attachments",
  POSTS_ATTACHMENT_CREATE = "/post/{postId}/attachments",
  POSTS_ATTACHMENT_DELETE = "/post/{postId}/attachments/{id}",

  POSTS_COMMENTS_LIST = "/post/{postId}/comments",
  POSTS_COMMENTS_CREATE = "/post/{postId}/comments",
  POSTS_COMMENTS_DELETE = "/post/{postId}/comments/{id}",

  REPORTS_LIST = "/reports",
  REPORTS_GET = "/reports/{id}",
  REPORTS_CREATE = "/reports",
  REPORTS_UPDATE = "/reports/{id}",
  REPORTS_UPDATE_STATUS = "/reports/{id}/status",
  REPORTS_DELETE = "/reports/{id}",

  REPORTS_ATTACHMENTS_CREATE = "/reports/{reportId}/attachments",

  TEAM_BUILDING_LIST = "/teambuilding",
  TEAM_BUILDING_EMPLOYEE = "/teambuilding/employee",
  TEAM_BUILDING_GET = "/teambuilding/{id}",
  TEAM_BUILDING_CREATE = "/teambuilding",
  TEAM_BUILDING_UPDATE = "/teambuilding/{id}",
  TEAM_BUILDING_UPDATE_STATUS = "/teambuilding/{id}/status",
  TEAM_BUILDING_SEND = "/teambuilding/{id}/send",
  TEAM_BUILDING_DELETE = "/teambuilding/{id}",

  ANNOUNCEMENTS_LIST = "/announcements",
  ANNOUNCEMENTS_COMPANY_LIST = "/announcements/company",
  ANNOUNCEMENTS_GET = "/announcements/{id}",
  ANNOUNCEMENTS_CREATE = "/announcements",
  ANNOUNCEMENTS_UPDATE = "/announcements/{id}",
  ANNOUNCEMENTS_DELETE = "/announcements/{id}",
  ANNOUNCEMENTS_READ = "/announcements/{id}/seen",

  ANNOUNCEMENTS_ATTACHMENT_LIST = "/announcements/{announcementId}/attachments",
  ANNOUNCEMENTS_ATTACHMENT_CREATE = "/announcements/{announcementId}/attachments",
  ANNOUNCEMENTS_ATTACHMENT_DELETE = "/announcements/{announcementId}/attachments/{id}",

  ANNOUNCEMENTS_TYPES_LIST = "/announcementtypes",
  ANNOUNCEMENTS_TYPES_GET = "/announcementtypes/{id}",

  BENEFIT_CATEGORIES_LIST = "/benefit-categories",
  BENEFIT_CATEGORIES_CONFIG = "/benefit-categories/config",
  BENEFIT_CATEGORIES_GET = "/benefit-categories/{id}",
  BENEFIT_CATEGORIES_CREATE = "/benefit-categories",
  BENEFIT_CATEGORIES_UPDATE = "/benefit-categories/{id}",
  BENEFIT_CATEGORIES_UPDATE_ACTIVE = "/benefit-categories/{id}/active",
  BENEFIT_CATEGORIES_UPDATE_VISIBILITY = "/benefit-categories/config/{id}",
  BENEFIT_CATEGORIES_DELETE = "/benefit-categories/{id}",

  BENEFIT_KEYWORDS_LIST = "/benefit-keywords",
  BENEFIT_KEYWORDS_GET = "/benefit-keywords/{id}",
  BENEFIT_KEYWORDS_CREATE = "/benefit-keywords",
  BENEFIT_KEYWORDS_UPDATE = "/benefit-keywords/{id}",
  BENEFIT_KEYWORDS_DELETE = "/benefit-keywords/{id}",

  BENEFITS_LIST = "/benefits",
  BENEFITS_PAGINATE = "/benefits/paginate",
  BENEFITS_CONFIG = "/benefits/config",
  BENEFITS_CONFIG_PAGINATE = "/benefits/config/paginate",
  BENEFITS_GET = "/benefits/{id}",
  BENEFITS_CREATE = "/benefits",
  BENEFITS_UPDATE = "/benefits/{id}",
  BENEFITS_UPDATE_ACTIVE = "/benefits/{id}/active",
  BENEFITS_UPDATE_VISIBILITY = "/benefits/config/{id}",
  BENEFITS_DELETE = "/benefits/{id}",
  BENEFITS_REDEEM = "/benefits/{benefitId}/redemptions",
  BENEFITS_REDEEM_PLACES_LIST = "/benefits/{benefitId}/redemptions/places",

  BENEFITS_SYNC_PAGINATE = "/benefits/sync",
  BENEFITS_SYNC_CREATE = "/benefits/sync",

  HR_SYNC_PAGINATE = "/hr/sync",
  HR_SYNC_CREATE = "/hr/sync",

  ATTENDANCE_SYNC_PAGINATE = "/attendances/sync",
  ATTENDANCE_SYNC_CREATE = "/attendances/sync",

  INTERNAL_BENEFIT_CATEGORIES_LIST = "/internal-benefits/categories",
  INTERNAL_BENEFIT_CATEGORIES_GET = "/internal-benefits/categories/{id}",
  INTERNAL_BENEFIT_CATEGORIES_CREATE = "/internal-benefits/categories",
  INTERNAL_BENEFIT_CATEGORIES_UPDATE = "/internal-benefits/categories/{id}",
  INTERNAL_BENEFIT_CATEGORIES_DELETE = "/internal-benefits/categories/{id}",

  INTERNAL_BENEFITS_LIST = "/internal-benefits",
  INTERNAL_BENEFITS_GET = "/internal-benefits/{id}",
  INTERNAL_BENEFITS_CREATE = "/internal-benefits",
  INTERNAL_BENEFITS_UPDATE = "/internal-benefits/{id}",
  INTERNAL_BENEFITS_DELETE = "/internal-benefits/{id}",

  EVENT_CATEGORIES_LIST = "/events/categories",
  EVENT_CATEGORIES_EMPLOYEE = "/event-categories",
  EVENT_CATEGORIES_CONFIG = "/event-categories/config",
  EVENT_CATEGORIES_GET = "/events/categories/{id}",
  EVENT_CATEGORIES_CREATE = "/events/categories",
  EVENT_CATEGORIES_UPDATE = "/events/categories/{id}",
  EVENT_CATEGORIES_UPDATE_VISIBILITY = "/event-categories/config/{id}",
  EVENT_CATEGORIES_DELETE = "/events/categories/{id}",

  EVENTS_LIST = "/events",
  EVENTS_CONFIG = "/events/config",
  EVENTS_GET = "/events/{id}",
  EVENTS_CREATE = "/events",
  EVENTS_UPDATE = "/events/{id}",
  EVENTS_UPDATE_VISIBILITY = "/events/config/{id}",
  EVENTS_DELETE = "/events/{id}",

  PROVIDERS_LIST = "/providers",
  PROVIDERS_GET = "/providers/{id}",
  PROVIDERS_CREATE = "/providers",
  PROVIDERS_UPDATE = "/providers/{id}",
  PROVIDERS_DELETE = "/providers/{id}",

  APPOINTMENTS_CATEGORIES_LIST = "/appointments/categories",
  APPOINTMENTS_CATEGORIES_GET = "/appointments/categories/{id}",
  APPOINTMENTS_CATEGORIES_CREATE = "/appointments/categories",
  APPOINTMENTS_CATEGORIES_UPDATE = "/appointments/categories/{id}",
  APPOINTMENTS_CATEGORIES_DELETE = "/appointments/categories/{id}",

  APPOINTMENTS_LIST = "/appointments",
  APPOINTMENTS_ME_LIST = "/appointments/me",
  APPOINTMENTS_GET = "/appointments/{id}",
  APPOINTMENTS_CREATE = "/appointments/admin",
  APPOINTMENTS_CREATE_EMPLOYEE = "/appointments",
  APPOINTMENTS_UPDATE = "/appointments/{id}",
  APPOINTMENTS_DELETE = "/appointments/{id}",

  APPOINTMENTS_ATTACHMENT_LIST = "/appointments/{appointmentId}/attachments",
  APPOINTMENTS_ATTACHMENT_CREATE = "/appointments/{appointmentId}/attachments",
  APPOINTMENTS_ATTACHMENT_DELETE = "/appointments/{appointmentId}/attachments/{id}",

  SURVEYS_QUESTION_TYPES_LIST = "/surveys/questions/types",

  SURVEYS_CENTRAL_LIST = "/surveys",
  SURVEYS_CENTRAL_GET = "/surveys/{id}",
  SURVEYS_CENTRAL_CREATE = "/surveys",
  SURVEYS_CENTRAL_UPDATE = "/surveys/{id}",
  SURVEYS_CENTRAL_UPDATE_STATUS = "/surveys/{id}/status",
  SURVEYS_CENTRAL_DELETE = "/surveys/{id}",

  SURVEYS_CENTRAL_ATTACHMENT_LIST = "/surveys/questions/{questionId}/attachments",
  SURVEYS_CENTRAL_ATTACHMENT_CREATE = "/surveys/questions/{questionId}/attachments",
  SURVEYS_CENTRAL_ATTACHMENT_DELETE = "/surveys/questions/{questionId}/attachments/{questionId}",

  SURVEYS_CENTRAL_OPTION_ATTACHMENT_CREATE = "/surveys/questions/options/{optionId}/attachments",
  SURVEYS_CENTRAL_OPTION_ATTACHMENT_DELETE = "/surveys/questions/options/{optionId}/attachments/{id}",

  SURVEYS_CENTRAL_ANSWER_LIST = "/surveys/{id}/questions/{questionId}/answers",
  SURVEYS_CENTRAL_SUMMARY_LIST = "/surveys/{id}/questions/summary",
  SURVEYS_CENTRAL_SUMMARY_EXPORT = "/surveys/{id}/questions/export",

  SURVEYS_COMPANY_LIST = "/surveys/admin",
  SURVEYS_COMPANY_EMPLOYEE = "/surveys",
  SURVEYS_COMPANY_EMPLOYEE_OVERDUE = "/surveys/overdue",
  SURVEYS_COMPANY_GET = "/surveys/{id}",
  SURVEYS_COMPANY_CREATE = "/surveys/admin",
  SURVEYS_COMPANY_UPDATE = "/surveys/{id}",
  SURVEYS_COMPANY_UPDATE_STATUS = "/surveys/{id}/status",
  SURVEYS_COMPANY_DELETE = "/surveys/{id}",

  SURVEYS_COMPANY_ATTACHMENT_LIST = "/surveys/questions/{questionId}/attachments",
  SURVEYS_COMPANY_ATTACHMENT_CREATE = "/surveys/questions/{questionId}/attachments",
  SURVEYS_COMPANY_ATTACHMENT_DELETE = "/surveys/questions/{questionId}/attachments/{id}",

  SURVEYS_COMPANY_OPTION_ATTACHMENT_CREATE = "/surveys/questions/options/{optionId}/attachments",
  SURVEYS_COMPANY_OPTION_ATTACHMENT_DELETE = "/surveys/questions/options/{optionId}/attachments/{id}",

  SURVEYS_COMPANY_ANSWER_LIST = "/surveys/{id}/questions/{questionId}/answers",
  SURVEYS_COMPANY_SUMMARY_LIST = "/surveys/{id}/questions/summary",
  SURVEYS_COMPANY_SUMMARY_EXPORT = "/surveys/{id}/questions/export",

  SURVEYS_COMPANY_ANSWERS_VALIDATE = "/surveys/{id}/answers/validate",
  SURVEYS_COMPANY_ANSWERS_SEND = "/surveys/{id}/answers",

  RESTAURANTS_LIST = "/restaurants",
  RESTAURANTS_LIST_PUBLIC = "/restaurants/public",
  RESTAURANTS_GET = "/restaurants/{id}",
  RESTAURANTS_CREATE = "/restaurants",
  RESTAURANTS_UPDATE = "/restaurants/{id}",
  RESTAURANTS_UPDATE_IMAGES = "/restaurants/{id}/images",
  RESTAURANTS_UPDATE_PIN = "/restaurants/{id}/pin",
  RESTAURANTS_VERIFY_PIN = "/restaurants/{id}/pin/verify",
  RESTAURANTS_RESET_PIN = "/restaurants/{id}/pin/reset",
  RESTAURANTS_ACTIVE = "/restaurants/{id}/active",
  RESTAURANTS_DELETE = "/restaurants/{id}",

  RESTAURANTS_DEALS_GET = "/restaurants/{restaurantId}/deals",
  RESTAURANTS_DEALS_UPDATE = "/restaurants/{restaurantId}/deals",
  RESTAURANTS_DEALS_ACTIVE = "/restaurants/{restaurantId}/deals/active",
  RESTAURANTS_DEALS_DELETE = "/restaurants/{restaurantId}/deals",

  RESTAURANTS_SCHEDULE_LIST = "/restaurants/{restaurantId}/schedule",
  RESTAURANTS_SCHEDULE_SET = "/restaurants/{restaurantId}/schedule",
  RESTAURANTS_SCHEDULE_UPDATE = "/restaurants/{restaurantId}/schedule/{id}",
  RESTAURANTS_SCHEDULE_DELETE = "/restaurants/{restaurantId}/schedule/{id}",

  RESTAURANTS_COMPANY_DEALS_LIST = "/restaurants/{restaurantId}/companies",
  RESTAURANTS_COMPANY_DEALS_GET = "/restaurants/{restaurantId}/companies/{id}",

  REDEMPTION_CUT_TYPE_LIST = "/redemptions/cuttypes",

  REDEMPTION_COMPANY_CONFIG_GET = "/redemptions/config",
  REDEMPTION_COMPANY_CONFIG_USER_GET = "/redemptions/config/me",
  REDEMPTION_COMPANY_CONFIG_CREATE = "/redemptions/config",

  REDEMPTION_LIST = "/redemptions",
  REDEMPTION_EXPORT = "/redemptions/export",
  REDEMPTION_EXPORT_RESTAURANT = "/redemptions/export/restaurant",
  REDEMPTION_EMPLOYEE_LIST = "/redemptions/employee",
  REDEMPTION_GET = "/redemptions/{id}",
  REDEMPTION_CREATE = "/redemptions",
  REDEMPTION_CODE_CREATE = "/redemptions/codes",
  REDEMPTION_CODE_GET = "/redemptions/codes/{code}",
  REDEMPTION_RATE = "/redemptions/{id}/rate",

  REDEMPTION_CUT_LIST = "/redemptions/cuts",
  REDEMPTION_CUT_EXPORT = "/redemptions/cuts/export",
  REDEMPTION_CUT_GET = "/redemptions/cuts/{id}",
  REDEMPTION_CUT_SET_PAID = "/redemptions/cuts/{id}",
  REDEMPTION_CUT_GET_REDEMPTIONS = "/redemptions/cuts/{id}/redemptions",

  FEATURE_FLAG_LIST = "/feature-flags",
  FEATURE_FLAG_CREATE = "/feature-flags",
  FEATURE_FLAG_GET = "/feature-flags/{id}",
  FEATURE_FLAG_UPDATE = "/feature-flags/{id}",
  FEATURE_FLAG_DELETE = "/feature-flags/{id}",

  FEATURE_FLAGS_FOR_COMPANY = "/companies/{companyId}/feature-flags",
  FEATURE_FLAGS_COMPANY_ACTIVATION = "/companies/{companyId}/feature-flags",
  FEATURE_FLAGS_COMPANY_CURRENT = "/companies/{companyId}/feature-flags/current",
  FEATURE_FLAGS_COMPANY_CONFIG = "/companies/{companyId}/feature-flags/config",

  INTEGRATIONS_LIST = "/integrations",
  INTEGRATIONS_LIST_ACTIVE = "/integrations/active",
  INTEGRATIONS_GET = "/integrations/{id}",
  INTEGRATIONS_UPDATE = "/integrations/{id}",
  INTEGRATIONS_UPDATE_IMAGES = "/integrations/{id}/images",
  INTEGRATIONS_ACTIVE = "/integrations/{id}/active",
  INTEGRATIONS_INIT = "/integrations/{id}/init",

  INTEGRATIONS_KEYS_LIST = "/integrations/{id}/keys",
  INTEGRATIONS_KEYS_UPDATE = "/integrations/{id}/keys",

  INTEGRATIONS_COMPANY_KEYS_LIST = "/companies/{companyId}/integrations/{id}/keys",
  INTEGRATIONS_COMPANY_KEYS_UPDATE = "/companies/{companyId}/integrations/{id}/keys",

  INTEGRATIONS_COMPANIES_LIST = "/integrations/{id}/companies",
  INTEGRATIONS_COMPANIES_UPDATE = "/integrations/{id}/companies",
  INTEGRATIONS_COMPANIES_UPDATE_SINGLE = "/integrations/{id}/companies/{companyId}/state",
  INTEGRATIONS_COMPANIES_INIT = "/integrations/{id}/companies/{companyId}/init",

  COMPANIES_INTEGRATION_LIST = "/companies/{id}/integrations",
  COMPANIES_INTEGRATION_UPDATE = "/companies/{id}/integrations",
  COMPANIES_INTEGRATION_UPDATE_SINGLE = "/companies/{id}/integrations/{integrationId}/state",

  BOOKINGS_CONFIG_LIST = "/bookings/configs",
  BOOKINGS_CONFIG_GET = "/bookings/config",

  SUBCOMPANIES_PERMISSIONS = "/subcompanies/permissions",
  SUBCOMPANIES_LIST = "/subcompanies",
  SUBCOMPANIES_EXPORT = "/subcompanies/export",
  SUBCOMPANIES_GET = "/subcompanies/{id}",
  SUBCOMPANIES_CREATE = "/subcompanies",
  SUBCOMPANIES_UPDATE = "/subcompanies/{id}",
  SUBCOMPANIES_DELETE = "/subcompanies/{id}",

  CALENDARS_PERMISSIONS = "/calendars/permissions",
  CALENDARS_LIST = "/calendars",
  CALENDARS_GET = "/calendars/{id}",
  CALENDARS_CREATE = "/calendars",
  CALENDARS_UPDATE = "/calendars/{id}",
  CALENDARS_DELETE = "/calendars/{id}",

  GROUPS_PERMISSIONS = "/groups/permissions",
  GROUPS_LIST = "/groups",
  GROUPS_GET = "/groups/{id}",
  GROUPS_CREATE = "/groups",
  GROUPS_UPDATE = "/groups/{id}",
  GROUPS_DELETE = "/groups/{id}",

  OFFICES_PERMISSIONS = "/offices/permissions",
  OFFICES_LIST = "/offices",
  OFFICES_EXPORT = "/offices/export",
  OFFICES_GET = "/offices/{id}",
  OFFICES_CREATE = "/offices",
  OFFICES_UPDATE = "/offices/{id}",
  OFFICES_DELETE = "/offices/{id}",

  ATTENDANCES_PERMISSIONS = "/attendances/permissions",
  ATTENDANCES_LIST = "/attendances",
  ATTENDANCES_GET = "/attendances/{id}",
  ATTENDANCES_CREATE = "/attendances",
  ATTENDANCES_UPDATE = "/attendances/{id}",
  ATTENDANCES_DELETE = "/attendances/{id}",

  ATTENDANCE_CATEGORIES_PERMISSIONS = "/attendances/categories/permissions",
  ATTENDANCE_CATEGORIES_LIST = "/attendances/categories",
  ATTENDANCE_CATEGORIES_GET = "/attendances/categories/{id}",
  ATTENDANCE_CATEGORIES_CREATE = "/attendances/categories",
  ATTENDANCE_CATEGORIES_UPDATE = "/attendances/categories/{id}",
  ATTENDANCE_CATEGORIES_DELETE = "/attendances/categories/{id}",

  ATTENDANCES_EMPLOYEES_LIST = "/attendances/employees",
  ATTENDANCES_EMPLOYEES_GET = "/attendances/employees/{id}",
}
