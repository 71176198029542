import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createBlock(_component_el_tooltip, {
    class: "box-item",
    effect: "dark",
    content: _ctx.tooltip,
    placement: "top"
  }, {
    default: _withCtx(() => [
      (_ctx.route)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: _ctx.route,
            class: _normalizeClass(`btn btn-icon ${_ctx.btnClass ?? 'btn-light'} mx-1`)
          }, {
            default: _withCtx(() => [
              _createElementVNode("i", {
                class: _normalizeClass(`fs-4 ${_ctx.icon}`)
              }, null, 2)
            ]),
            _: 1
          }, 8, ["to", "class"]))
        : (_openBlock(), _createElementBlock("a", {
            key: 1,
            class: _normalizeClass(`btn btn-icon ${_ctx.btnClass ?? 'btn-light'} mx-1`),
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('action')), ["prevent"]))
          }, [
            _createElementVNode("i", {
              class: _normalizeClass(`fs-4 ${_ctx.icon}`)
            }, null, 2)
          ], 2))
    ]),
    _: 1
  }, 8, ["content"]))
}