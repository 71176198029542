export default [
  {
    path: "/master/surveys",
    children: [
      {
        path: "/master/surveys/",
        name: "surveys-master-index",
        component: () => import("@/views/roles/master/surveys/Index.vue"),
        meta: {
          pageTitle: "Encuestas",
          breadcrumbs: ["Admin", "Encuestas"],
        },
      },
      {
        path: "/master/surveys/create",
        name: "surveys-master-create",
        component: () => import("@/views/roles/master/surveys/Create.vue"),
        meta: {
          pageTitle: "Encuestas",
          breadcrumbs: ["Admin", "Encuestas", "Create"],
        },
      },
      {
        path: "/master/surveys/edit/:id?",
        name: "surveys-master-edit",
        component: () => import("@/views/roles/master/surveys/Edit.vue"),
        meta: {
          pageTitle: "Encuestas",
          breadcrumbs: ["Admin", "Encuestas", "Edit"],
        },
      },
      {
        path: "/master/surveys/details/:id?",
        name: "surveys-master-details",
        component: () => import("@/views/roles/master/surveys/Details.vue"),
        meta: {
          pageTitle: "Encuestas",
          breadcrumbs: ["Admin", "Encuestas", "Detalles"],
        },
      },
      {
        path: "/master/surveys/details/:surveyId/answers/:id",
        name: "surveys-master-question-answers",
        component: () => import("@/views/roles/master/surveys/Answers.vue"),
        meta: {
          pageTitle: "Encuestas",
          breadcrumbs: ["Admin", "Encuestas", "Preguntas", "Respuestas"],
        },
      },
    ],
  },
];
