export default [
  {
    path: "/superadmin/areas",
    children: [
      {
        path: "/superadmin/areas/",
        name: "areas-index",
        component: () =>
          import("@/views/roles/super-admin/organization/areas/Index.vue"),
        meta: {
          pageTitle: "Areas",
          breadcrumbs: ["Admin", "Áreas"],
        },
      },
    ],
  },
];
