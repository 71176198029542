export default [
  {
    path: "/restaurant/config",
    children: [
      {
        path: "/restaurant/config/",
        name: "restaurant-config-index",
        component: () => import("@/views/roles/restaurant/config/Index.vue"),
        meta: {
          pageTitle: "Configuración del restaurante",
          breadcrumbs: ["Restaurante", "Configuración"],
        },
      },
      {
        path: "/restaurant/config/edit",
        name: "restaurant-config-edit",
        component: () => import("@/views/roles/restaurant/config/Edit.vue"),
        meta: {
          pageTitle: "Configuración del restaurante",
          breadcrumbs: ["Restaurante", "Configuración", "Edit"],
        },
      },
    ],
  },
];
