import UserRoutes from "./modules/users";
import CompaniesRoutes from "./modules/companies";
import AnnouncementsRoutes from "./modules/announcemenets";
import BenefitsRoutes from "./modules/benefits";
import ConfigRoutes from "./modules/config";
import EventsRoutes from "./modules/events";
import MealsRoutes from "./modules/meals";
import PartnersRoutes from "./modules/partners";
import SurveysRoutes from "./modules/surveys";
import IntegrationsRoutes from "./modules/integrations";
import featureFlagsRoutes from "./modules/feature-flags";

export default [
  {
    path: "/master",
    meta: {
      middleware: "auth",
    },
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    children: [
      ...UserRoutes,
      ...CompaniesRoutes,
      ...AnnouncementsRoutes,
      ...BenefitsRoutes,
      ...ConfigRoutes,
      ...EventsRoutes,
      ...MealsRoutes,
      ...PartnersRoutes,
      ...SurveysRoutes,
      ...IntegrationsRoutes,
      ...featureFlagsRoutes,
    ],
  },
];
