export default [
  {
    path: "/restaurant/companies",
    children: [
      {
        path: "/restaurant/companies/",
        name: "restaurants-companies-index",
        component: () => import("@/views/roles/restaurant/companies/Index.vue"),
        meta: {
          pageTitle: "Empresas",
          breadcrumbs: ["Admin", "Empresas"],
        },
      },
      {
        path: "/restaurant/companies/details/:id?",
        name: "restaurants-companies-details",
        component: () =>
          import("@/views/roles/restaurant/companies/Details.vue"),
        meta: {
          pageTitle: "Restaurantes",
          breadcrumbs: ["Admin", "Empresas", "Detalles"],
        },
      },
    ],
  },
];
