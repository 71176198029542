export default [
  {
    path: "/master/integrations",
    children: [
      {
        path: "/master/integrations/",
        name: "integrations-index",
        component: () => import("@/views/roles/master/integrations/Index.vue"),
        meta: {
          pageTitle: "Integraciones",
          breadcrumbs: ["Admin", "Integraciones"],
        },
      },
      {
        path: "/master/integrations/edit/:id?",
        name: "integrations-edit",
        component: () => import("@/views/roles/master/integrations/Edit.vue"),
        meta: {
          pageTitle: "Integraciones",
          breadcrumbs: ["Admin", "Integraciones", "Edit"],
        },
      },
      {
        path: "/master/integrations/keys/:id?",
        name: "integrations-keys",
        component: () => import("@/views/roles/master/integrations/Keys.vue"),
        meta: {
          pageTitle: "Integraciones",
          breadcrumbs: ["Admin", "Integraciones", "Llaves"],
        },
      },
      {
        path: "/master/integrations/companies/:id?",
        name: "integrations-companies",
        component: () =>
          import("@/views/roles/master/integrations/Companies.vue"),
        meta: {
          pageTitle: "Integraciones",
          breadcrumbs: ["Admin", "Integraciones", "Empresas"],
        },
      },
      {
        path: "/master/integrations/companies/manage/:id?",
        name: "integrations-companies-manage",
        component: () =>
          import("@/views/roles/master/integrations/CompaniesManage.vue"),
        meta: {
          pageTitle: "Integraciones",
          breadcrumbs: ["Admin", "Integraciones", "Empresas"],
        },
      },
      {
        path: "/master/integrations/keys/:id?/company/:companyId?",
        name: "integrations-company-keys",
        component: () =>
          import("@/views/roles/master/integrations/CompanyKeys.vue"),
        meta: {
          pageTitle: "Integraciones",
          breadcrumbs: ["Admin", "Integraciones", "Llaves"],
        },
      },
    ],
  },
];
