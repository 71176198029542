import {
  IntegrationFieldDto,
  IntegrationFieldModel,
} from "./integration-field.model";
import { IntegrationTypeModel } from "./integration-type.model";

export enum IntegrationTypeEnum {
  BENEFITS = "benefits",
  BOOKINGS = "bookings",
  HR = "hr",
  ATTENDANCES = "attendances",
}

export interface IntegrationModel {
  id: string;
  key: string;
  name: string;
  icon: string;
  companyKeysAllowed: boolean;
  type: IntegrationTypeModel;
  fields: IntegrationFieldModel[];
}

export class IntegrationDto {
  name = "";
  fields: IntegrationFieldDto[] = [];
}

export interface QueryIntegrationDto {
  type?: IntegrationTypeEnum;
  activeOnly?: boolean;
}
