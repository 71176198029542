export default [
  {
    path: "/superadmin/calendars",
    children: [
      {
        path: "/superadmin/calendars/",
        name: "calendars-index",
        component: () =>
          import("@/views/roles/super-admin/calendars/Index.vue"),
        meta: {
          pageTitle: "Calendarios",
          breadcrumbs: ["Admin", "Calendarios"],
        },
      },
      {
        path: "/superadmin/calendars/create",
        name: "calendars-create",
        component: () =>
          import("@/views/roles/super-admin/calendars/Create.vue"),
        meta: {
          pageTitle: "Calendarios",
          breadcrumbs: ["Admin", "Calendarios", "Create"],
        },
      },
      {
        path: "/superadmin/calendars/edit/:id?",
        name: "calendars-edit",
        component: () => import("@/views/roles/super-admin/calendars/Edit.vue"),
        meta: {
          pageTitle: "Calendarios",
          breadcrumbs: ["Admin", "Calendarios", "Edit"],
        },
      },
    ],
  },
];
