import axios, { AxiosRequestConfig } from "axios";
import JwtService from "@/core/services/JwtService";
import store from "@/store";
import { Nullable } from "../helpers/customtypes";
import { CompanyModel } from "@/models/companies/company.model";

export const authAxios = axios.create();
authAxios.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = JwtService.getToken();
  if (!token) {
    return config;
  }

  config.headers = config.headers ?? {};
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

export const companyAxios = axios.create();
companyAxios.interceptors.request.use((config: AxiosRequestConfig) => {
  config.headers = config.headers ?? {};

  const token = JwtService.getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  const currentCompany = store.getters.currentCompany as Nullable<CompanyModel>;
  if (currentCompany?.key) {
    config.headers["X-Company"] = currentCompany.key;
  }

  return config;
});
