import { Api } from "@/core/services/Api";
import { companyAxios } from "@/core/services/CustomAxios";
import ServiceHandler from "@/core/services/ServiceHandler";
import { Services } from "../api.config";
import { ModulesMap } from "@/models/modules/modules.model";

class ModulesService {
  public async getConfig(): Promise<ModulesMap> {
    try {
      const serviceUrl = Api.getCompanyServiceUrl(Services.MODULES_CONFIG);
      return await companyAxios
        .get(serviceUrl)
        .then(ServiceHandler.checkSuccess<ModulesMap>())
        .catch(ServiceHandler.catchError());
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export const modulesService = new ModulesService();
