export default [
  {
    path: "/superadmin/calendar",
    children: [
      {
        path: "/superadmin/calendar/",
        name: "calendar-index",
        component: () => import("@/views/roles/super-admin/calendar/Index.vue"),
        meta: {
          pageTitle: "Calendario",
          breadcrumbs: ["Admin", "Calendario"],
        },
      },
      {
        path: "/superadmin/calendar/categories",
        name: "calendar-categories-index",
        component: () =>
          import("@/views/roles/super-admin/calendar/categories/Index.vue"),
        meta: {
          pageTitle: "Categorías de calendario",
          breadcrumbs: ["Admin", "Calendario", "Categorías"],
        },
      },
      {
        path: "/superadmin/calendar/categories/create",
        name: "calendar-categories-create",
        component: () =>
          import("@/views/roles/super-admin/calendar/categories/Create.vue"),
        meta: {
          pageTitle: "Categorías de calendario",
          breadcrumbs: ["Admin", "Calendario", "Categorías", "Create"],
        },
      },
      {
        path: "/superadmin/calendar/categories/edit/:id?",
        name: "calendar-categories-edit",
        component: () =>
          import("@/views/roles/super-admin/calendar/categories/Edit.vue"),
        meta: {
          pageTitle: "Categorías de calendario",
          breadcrumbs: ["Admin", "Calendario", "Categorías", "Edit"],
        },
      },
    ],
  },
];
