export default [
  {
    path: "/superadmin/offices",
    children: [
      {
        path: "/superadmin/offices/",
        name: "offices-index",
        component: () => import("@/views/roles/super-admin/offices/Index.vue"),
        meta: {
          pageTitle: "Oficinas",
          breadcrumbs: ["Admin", "Oficinas"],
        },
      },
      {
        path: "/superadmin/offices/create",
        name: "offices-create",
        component: () => import("@/views/roles/super-admin/offices/Create.vue"),
        meta: {
          pageTitle: "Oficinas",
          breadcrumbs: ["Admin", "Oficinas", "Create"],
        },
      },
      {
        path: "/superadmin/offices/edit/:id?",
        name: "offices-edit",
        component: () => import("@/views/roles/super-admin/offices/Edit.vue"),
        meta: {
          pageTitle: "Oficinas",
          breadcrumbs: ["Admin", "Oficinas", "Edit"],
        },
      },
    ],
  },
];
