export default [
  {
    path: "/employee/profile",
    children: [
      {
        path: "/employee/profile/",
        name: "profile-index",
        component: () => import("@/views/roles/employee/profile/Index.vue"),
        meta: {
          pageTitle: "Mi perfil",
          breadcrumbs: ["Empleados", "Perfil"],
        },
      },
      {
        path: "/employee/profile/edit",
        name: "profile-edit",
        component: () => import("@/views/roles/employee/profile/Edit.vue"),
        meta: {
          pageTitle: "Mi perfil",
          breadcrumbs: ["Empleados", "Perfil", "Editar"],
        },
      },
      {
        path: "/employee/profile/questions",
        name: "profile-questions",
        component: () =>
          import("@/views/roles/employee/profile/QuestionForm.vue"),
        meta: {
          pageTitle: "Cuestionario Inicial",
          breadcrumbs: ["Empleados", "Perfil", "Cuestionario Inicial"],
        },
      },
    ],
  },
];
