export default [
  {
    path: "/master/companies",
    children: [
      {
        path: "/master/companies/",
        name: "companies-index",
        component: () =>
          import("@/views/roles/master/admin/companies/Index.vue"),
        meta: {
          pageTitle: "Empresas",
          breadcrumbs: ["Admin", "Empresas"],
        },
      },
      {
        path: "/master/companies/create",
        name: "companies-create",
        component: () =>
          import("@/views/roles/master/admin/companies/Create.vue"),
        meta: {
          pageTitle: "Empresas",
          breadcrumbs: ["Admin", "Empresas", "Create"],
        },
      },
      {
        path: "/master/companies/edit/:id?",
        name: "companies-edit",
        component: () =>
          import("@/views/roles/master/admin/companies/Edit.vue"),
        meta: {
          pageTitle: "Empresas",
          breadcrumbs: ["Admin", "Empresas", "Edit"],
        },
      },
      {
        path: "/master/companies/users/:companyId?",
        name: "company-users-index",
        component: () =>
          import("@/views/roles/master/admin/company-users/Index.vue"),
        meta: {
          pageTitle: "Administradores de empresas",
          breadcrumbs: ["Admin", "Empresas", "Super administradores"],
        },
      },
      {
        path: "/master/companies/users/:companyId?/create",
        name: "company-users-create",
        component: () =>
          import("@/views/roles/master/admin/company-users/Create.vue"),
        meta: {
          pageTitle: "Administradores de empresas",
          breadcrumbs: ["Admin", "Empresas", "Super administradores", "Create"],
        },
      },
      {
        path: "/master/companies/users/:companyId?/edit/:id?",
        name: "company-users-edit",
        component: () =>
          import("@/views/roles/master/admin/company-users/Edit.vue"),
        meta: {
          pageTitle: "Administradores de empresas",
          breadcrumbs: ["Admin", "Empresas", "Super administradores", "Edit"],
        },
      },
      {
        path: "/master/companies/integrations/:id?",
        name: "company-integrations-edit",
        component: () =>
          import("@/views/roles/master/admin/companies/Integrations.vue"),
        meta: {
          pageTitle: "Administradores de empresas",
          breadcrumbs: ["Admin", "Empresas", "Integraciones"],
        },
      },
      {
        path: "/master/companies/feature-flags/:id?",
        name: "company-featureflags-activation",
        component: () =>
          import(
            "@/views/roles/master/admin/company-featureflags/Activation.vue"
          ),
        meta: {
          pageTitle: "Módulos de empresas",
          breadcrumbs: ["Admin", "Empresas", "Módulos", "Activación"],
        },
      },
      {
        path: "/master/companies/extra-info/:id?",
        name: "company-extra-info",
        component: () =>
          import("@/views/roles/master/admin/company-extrainfo/Edit.vue"),
        meta: {
          pageTitle: "Información adicional",
          breadcrumbs: ["Admin", "Empresas", "Información", "Adicional"],
        },
      },
    ],
  },
];
