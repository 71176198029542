export default [
  {
    path: "/superadmin/releases",
    children: [
      {
        path: "/superadmin/releases/",
        name: "releases-index",
        component: () =>
          import("@/views/roles/super-admin/communication/releases/Index.vue"),
        meta: {
          pageTitle: "Comunicados",
          breadcrumbs: ["Admin", "Comunicados"],
        },
      },
      {
        path: "/superadmin/releases/create",
        name: "releases-create",
        component: () =>
          import("@/views/roles/super-admin/communication/releases/Create.vue"),
        meta: {
          pageTitle: "Comunicados",
          breadcrumbs: ["Admin", "Comunicados", "Create"],
        },
      },
      {
        path: "/superadmin/releases/edit/:id?",
        name: "releases-edit",
        component: () =>
          import("@/views/roles/super-admin/communication/releases/Edit.vue"),
        meta: {
          pageTitle: "Comunicados",
          breadcrumbs: ["Admin", "Comunicados", "Edit"],
        },
      },
    ],
  },
];
