enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  UPDATE_EMPLOYEE_INFO = "updateEmployeeInfoAction",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_THEME_MODE_ACTION = "setThemeModeAction",

  SET_ROLE_CONFIG = "setRoleConfigAction",
  INIT_COMPANY = "initCompanyAction",

  SET_PIN_ACCESS = "setPinAccessAction",
  UPDATE_MODULES_CONFIG = "updateModulesConfigAction",
  REDIRECT_DEFAULT_EMPLOYEE = "redirectDefaultEmployeeAction",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_EMPLOYEE = "setEmployee",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG_PROPERTY = "setLayoutConfigProperty",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_THEME_MODE_MUTATION = "setThemeModeMutation",

  SET_COMPANY = "setCompanyMutation",
  SET_ROLE = "setRoleMutation",

  SET_PIN_ACCESS = "setPinAccessMutation",
  SET_MODULES_CONFIG = "setModulesConfigMutation",
}

export { Actions, Mutations };
