export default [
  {
    path: "/restaurant/redemptions",
    children: [
      {
        path: "/restaurant/redemptions/",
        name: "restaurants-redemptions-index",
        component: () =>
          import("@/views/roles/restaurant/redemptions/Index.vue"),
        meta: {
          pageTitle: "Redenciones",
          breadcrumbs: ["Admin", "Redenciones"],
        },
      },
      {
        path: "/restaurant/redemptions/rates",
        name: "restaurants-redemptions-rates",
        component: () =>
          import("@/views/roles/restaurant/redemptions/Rates.vue"),
        meta: {
          pageTitle: "Redenciones",
          breadcrumbs: ["Admin", "Redenciones", "Calificaciones"],
        },
      },
      {
        path: "/restaurant/redemptions/details/:id?",
        name: "restaurants-redemptions-details",
        component: () =>
          import("@/views/roles/restaurant/redemptions/Detail.vue"),
        meta: {
          pageTitle: "Redenciones",
          breadcrumbs: ["Admin", "Redenciones"],
        },
      },
      {
        path: "/restaurant/redemptions/redeem",
        name: "restaurants-redemptions-redeem",
        component: () => import("@/views/roles/restaurant/redeem/Index.vue"),
        meta: {
          pageTitle: "Redenciones",
          breadcrumbs: ["Admin", "Redenciones"],
        },
      },
      {
        path: "/restaurant/redemptions/cuts",
        name: "restaurants-redemptions-cuts",
        component: () =>
          import("@/views/roles/restaurant/redemptions-cuts/Index.vue"),
        meta: {
          pageTitle: "Redenciones",
          breadcrumbs: ["Admin", "Redenciones", "Cortes"],
        },
      },
      {
        path: "/restaurant/redemptions/cuts/details/:id?",
        name: "restaurants-redemptions-cuts-details",
        component: () =>
          import("@/views/roles/restaurant/redemptions-cuts/Detail.vue"),
        meta: {
          pageTitle: "Redenciones",
          breadcrumbs: ["Admin", "Redenciones", "Cortes"],
        },
      },
    ],
  },
];
