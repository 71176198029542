export default [
  {
    path: "/superadmin/events/config",
    children: [
      {
        path: "/superadmin/events/config/",
        name: "events-category-config",
        component: () =>
          import("@/views/roles/super-admin/events/event-categories/Index.vue"),
        meta: {
          pageTitle: "Eventos",
          breadcrumbs: ["Admin", "Eventos"],
        },
      },
      {
        path: "/superadmin/events/config/:id?/",
        name: "events-config",
        component: () =>
          import(
            "@/views/roles/super-admin/events/event-categories/Details.vue"
          ),
        meta: {
          pageTitle: "Eventos",
          breadcrumbs: ["Admin", "Eventos", "Detalles"],
        },
      },
      {
        path: "/superadmin/events/config/:id?/details",
        name: "events-details",
        component: () =>
          import("@/views/roles/super-admin/events/events/Details.vue"),
        meta: {
          pageTitle: "Eventos",
          breadcrumbs: ["Admin", "Eventos", "Detalles"],
        },
      },
    ],
  },
];
