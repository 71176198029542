export default [
  {
    path: "/employee/help",
    children: [
      {
        path: "/employee/help/",
        name: "help-index",
        component: () => import("@/views/roles/employee/help/Index.vue"),
        meta: {
          pageTitle: "Centro de ayuda",
          breadcrumbs: ["Empleados", "Ayuda"],
        },
      },
    ],
  },
];
