
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "action-button",
  props: {
    tooltip: { type: String, required: true },
    icon: { type: String, required: true },
    route: { type: Object },
    btnClass: { type: String },
  },
  emits: ["action"],
  setup() {
    return {};
  },
});
