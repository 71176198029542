export default [
  {
    path: "/superadmin/partners",
    children: [
      {
        path: "/superadmin/partners/",
        name: "partners-index",
        component: () => import("@/views/roles/super-admin/partners/Index.vue"),
        meta: {
          pageTitle: "Partners",
          breadcrumbs: ["Admin", "Partners"],
        },
      },
      {
        path: "/superadmin/partners/detail/:id?",
        name: "partner-details",
        component: () =>
          import("@/views/roles/super-admin/partners/Detail.vue"),
        meta: {
          pageTitle: "Detalle partner",
          breadcrumbs: ["Admin", "Partners", "Detail"],
        },
      },
    ],
  },
];
