export default [
  {
    path: "/superadmin/groups",
    children: [
      {
        path: "/superadmin/groups/",
        name: "groups-index",
        component: () =>
          import("@/views/roles/super-admin/employees/groups/Index.vue"),
        meta: {
          pageTitle: "Grupos",
          breadcrumbs: ["Admin", "Grupos"],
        },
      },
      {
        path: "/superadmin/groups/create",
        name: "groups-create",
        component: () =>
          import("@/views/roles/super-admin/employees/groups/Create.vue"),
        meta: {
          pageTitle: "Grupos",
          breadcrumbs: ["Admin", "Grupos", "Create"],
        },
      },
      {
        path: "/superadmin/groups/edit/:id?",
        name: "groups-edit",
        component: () =>
          import("@/views/roles/super-admin/employees/groups/Edit.vue"),
        meta: {
          pageTitle: "Grupos",
          breadcrumbs: ["Admin", "Grupos", "Edit"],
        },
      },
    ],
  },
];
