export default [
  {
    path: "/superadmin/news",
    children: [
      {
        path: "/superadmin/news/",
        name: "news-index",
        component: () =>
          import("@/views/roles/super-admin/communication/news/Index.vue"),
        meta: {
          pageTitle: "Noticias",
          breadcrumbs: ["Admin", "Noticias"],
        },
      },
      {
        path: "/superadmin/news/create",
        name: "news-create",
        component: () =>
          import("@/views/roles/super-admin/communication/news/Create.vue"),
        meta: {
          pageTitle: "Noticias",
          breadcrumbs: ["Admin", "Noticias", "Create"],
        },
      },
      {
        path: "/superadmin/news/edit/:id?",
        name: "news-edit",
        component: () =>
          import("@/views/roles/super-admin/communication/news/Edit.vue"),
        meta: {
          pageTitle: "Noticias",
          breadcrumbs: ["Admin", "Noticias", "Edit"],
        },
      },
    ],
  },
];
