export default [
  {
    path: "/employee/news",
    children: [
      {
        path: "/employee/news/",
        name: "news-eployee-index",
        component: () => import("@/views/roles/employee/news/Index.vue"),
        meta: {
          pageTitle: "Noticias",
          breadcrumbs: ["Empleados", "Noticias"],
        },
      },
      {
        path: "/employee/news/details/:id?",
        name: "news-employee-detail",
        component: () => import("@/views/roles/employee/news/Details.vue"),
        meta: {
          pageTitle: "Noticias",
          breadcrumbs: ["Empleados", "Noticias", "Detalles"],
        },
      },
    ],
  },
  {
    path: "/employee/releases",
    children: [
      {
        path: "/employee/releases/",
        name: "releases-eployee-index",
        component: () => import("@/views/roles/employee/releases/Index.vue"),
        meta: {
          pageTitle: "Comunicados",
          breadcrumbs: ["Empleados", "Comunicados"],
        },
      },
      {
        path: "/employee/releases/details/:id?",
        name: "releases-employee-detail",
        component: () => import("@/views/roles/employee/releases/Details.vue"),
        meta: {
          pageTitle: "Comunicados",
          breadcrumbs: ["Empleados", "Comunicados", "Detalles"],
        },
      },
    ],
  },
  {
    path: "/employee/posts",
    children: [
      {
        path: "/employee/posts/",
        name: "posts-eployee-index",
        component: () => import("@/views/roles/employee/posts/Index.vue"),
        meta: {
          pageTitle: "Publicaciones",
          breadcrumbs: ["Empleados", "Publicaciones"],
        },
      },
      {
        path: "/employee/posts/create/",
        name: "posts-eployee-create",
        component: () => import("@/views/roles/employee/posts/Create.vue"),
        meta: {
          pageTitle: "Publicaciones",
          breadcrumbs: ["Empleados", "Publicaciones", "Nueva publicación"],
        },
      },
      {
        path: "/employee/posts/details/:id?",
        name: "posts-employee-detail",
        component: () => import("@/views/roles/employee/posts/Details.vue"),
        meta: {
          pageTitle: "Publicaciones",
          breadcrumbs: ["Empleados", "Publicaciones", "Detalles"],
        },
      },
    ],
  },
];
