export default [
  {
    path: "/superadmin/hr/syncs",
    children: [
      {
        path: "/superadmin/hr/syncs/",
        name: "hr-syncs-index",
        component: () => import("@/views/roles/super-admin/hr/Syncs.vue"),
        meta: {
          pageTitle: "Sincronizaciones con sistema de RH",
          breadcrumbs: ["Admin", "RH", "Sincronizaciones"],
        },
      },
    ],
  },
];
