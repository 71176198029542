export default [
  {
    path: "/employee/home",
    children: [
      {
        path: "/employee/home/",
        name: "home-eployee-index",
        component: () => import("@/views/roles/employee/home/Index.vue"),
        meta: {
          pageTitle: "Home",
          breadcrumbs: ["Empleados"],
        },
      },
    ],
  },
];
