import { Nullable } from "@/core/helpers/customtypes";
import { Api } from "@/core/services/Api";
import { companyAxios } from "@/core/services/CustomAxios";
import ServiceHandler from "@/core/services/ServiceHandler";
import { CompanyModel } from "@/models/companies/company.model";
import { Services } from "../api.config";

class CompaniesService {
  public async getAll(search?: string): Promise<CompanyModel[]> {
    try {
      const serviceUrl = Api.getCentralServiceUrl(Services.COMPANIES_LIST);
      return await companyAxios
        .get(serviceUrl, {
          params: { search },
        })
        .then(ServiceHandler.checkSuccess<CompanyModel[]>())
        .catch(ServiceHandler.catchError());
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async get(id: string): Promise<CompanyModel> {
    try {
      const serviceUrl = Api.getCentralServiceUrl(Services.COMPANIES_GET, {
        id,
      });
      return await companyAxios
        .get(serviceUrl)
        .then(ServiceHandler.checkSuccess<CompanyModel>())
        .catch(ServiceHandler.catchError());
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async getByKey(key: string): Promise<CompanyModel> {
    try {
      const serviceUrl = Api.getCentralServiceUrl(
        Services.COMPANIES_GET_BY_KEY,
        {
          key,
        }
      );

      return await companyAxios
        .get(serviceUrl)
        .then(ServiceHandler.checkSuccess<CompanyModel>())
        .catch(ServiceHandler.catchError());
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async create(data: FormData): Promise<CompanyModel> {
    try {
      const serviceUrl = Api.getCentralServiceUrl(Services.COMPANIES_CREATE);
      return await companyAxios
        .post(serviceUrl, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(ServiceHandler.checkSuccess<CompanyModel>())
        .catch(ServiceHandler.catchError());
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async update(
    id: Nullable<string>,
    data: FormData
  ): Promise<CompanyModel> {
    try {
      const serviceUrl = Api.getCentralServiceUrl(Services.COMPANIES_UPDATE, {
        id,
      });
      return await companyAxios
        .put(serviceUrl, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(ServiceHandler.checkSuccess<CompanyModel>())
        .catch(ServiceHandler.catchError());
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async delete(id: Nullable<string>): Promise<void> {
    try {
      const serviceUrl = Api.getCentralServiceUrl(Services.COMPANIES_DELETE, {
        id,
      });
      await companyAxios
        .delete(serviceUrl)
        .then(ServiceHandler.checkSuccessEmpty())
        .catch(ServiceHandler.catchError());
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export const companiesService = new CompaniesService();
