export default [
  {
    path: "/employee/teambuilding",
    children: [
      {
        path: "/employee/teambuilding/",
        name: "teambuilding-eployee-index",
        component: () =>
          import("@/views/roles/employee/teambuilding/Index.vue"),
        meta: {
          pageTitle: "Team Building",
          breadcrumbs: ["Empleados", "Team Building"],
        },
      },
      {
        path: "/employee/teambuilding/create/",
        name: "teambuilding-eployee-create",
        component: () =>
          import("@/views/roles/employee/teambuilding/Create.vue"),
        meta: {
          pageTitle: "Team Building",
          breadcrumbs: ["Empleados", "Team Building", "Nueva publicación"],
        },
      },
    ],
  },
];
