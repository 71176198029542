import { Dictionary } from "@/core/helpers/customtypes";

export enum ModuleEnum {
  REPORTS = "moduleAnonymousReportsEnabled",
  BENEFITS = "moduleBenefitsEnabled",
  BENEFITSINTERNAL = "moduleBenefitsInternalEnabled",
  CALENDAR = "moduleCalendarEnabled",
  EVENTS = "moduleEventsEnabled",
  FAQS = "moduleFAQsEnabled",
  HELP_CHANNEL = "moduleHelpChannelsEnabled",
  MEALS = "moduleMealsEnabled",
  NEWS = "moduleNewsEnabled",
  POSTS = "modulePostsEnabled",
  RELEASES = "moduleReleasesEnabled",
  SURVEYS = "moduleSurveysEnabled",
  TEAM_BUILDING = "moduleTeamBuildingEnabled",
  PROFILE = "moduleProfileEnabled",
  moduleCustomAssetsInCentralEnabled = "moduleCustomAssetsInCentralEnabled",
  moduleWhiteLabelEnabled = "moduleWhiteLabelEnabled",
  BOOKINGS = "moduleBookingsEnabled",

  EXTERNAL_HR = "featureExternalHR",
  EXTERNAL_ATTENDANCES = "featureExternalAttendances",
}

export type ModulesMap = Dictionary<boolean>;
