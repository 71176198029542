export default [
  {
    path: "/superadmin/announcements",
    children: [
      {
        path: "/superadmin/announcements/",
        name: "announcements-read-index",
        component: () =>
          import(
            "@/views/roles/super-admin/communication/announcements/Index.vue"
          ),
        meta: {
          pageTitle: "Anuncios",
          breadcrumbs: ["Admin", "Anuncios"],
        },
      },
    ],
  },
];
