export default [
  {
    path: "/master/feature-flags",
    children: [
      {
        path: "/master/feature-flags/",
        name: "featureflags-index",
        component: () => import("@/views/roles/master/featureflags/Index.vue"),
        meta: {
          pageTitle: "Módulos",
          breadcrumbs: ["Admin", "Módulos"],
        },
      },
      {
        path: "/master/feature-flags/create",
        name: "featureflags-create",
        component: () => import("@/views/roles/master/featureflags/Create.vue"),
        meta: {
          pageTitle: "Módulos",
          breadcrumbs: ["Admin", "Módulos", "Creación"],
        },
      },
      {
        path: "/master/feature-flags/edit/:id?",
        name: "featureflags-edit",
        component: () => import("@/views/roles/master/featureflags/Edit.vue"),
        meta: {
          pageTitle: "Módulos	",
          breadcrumbs: ["Admin", "Módulos", "Edición"],
        },
      },
    ],
  },
];
