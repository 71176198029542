import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
const bugsnagVue = Bugsnag.getPlugin("vue");
import { App } from "vue";

if (process.env.VUE_APP_BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginVue()],
  });
}

export const initBugsnag = (app: App<Element>) => {
  if (!process.env.VUE_APP_BUGSNAG_API_KEY) return;
  if (!bugsnagVue) return;
  app.use(bugsnagVue);
};
