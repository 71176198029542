export default [
  {
    path: "/employee/meals",
    children: [
      {
        path: "/employee/meals/",
        name: "meals-index",
        component: () => import("@/views/roles/employee/meals/Index.vue"),
        meta: {
          pageTitle: "Comedor digital",
          breadcrumbs: ["Empleados", "Comedor"],
        },
      },
    ],
  },
];
