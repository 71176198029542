export default [
  {
    path: "/employee/benefits",
    children: [
      {
        path: "/employee/benefits/",
        name: "benefits-eployee-index",
        component: () => import("@/views/roles/employee/benefits/Index.vue"),
        meta: {
          pageTitle: "Beneficios",
          breadcrumbs: ["Empleados", "Beneficios"],
        },
      },
      {
        path: "/employee/benefits/details/:id?",
        name: "benefits-employee-detail",
        component: () => import("@/views/roles/employee/benefits/Details.vue"),
        meta: {
          pageTitle: "Beneficios",
          breadcrumbs: ["Empleados", "Beneficios", "Detalles"],
        },
      },
    ],
  },
];
