export default [
  {
    path: "/superadmin/teambuilding",
    children: [
      {
        path: "/superadmin/teambuilding/:status?",
        name: "teambuilding-index",
        component: () =>
          import("@/views/roles/super-admin/teambuilding/Index.vue"),
        meta: {
          pageTitle: "Team Building",
          breadcrumbs: ["Admin", "Team Building"],
        },
      },
      {
        path: "/superadmin/teambuilding/details/:id?",
        name: "teambuilding-details",
        component: () =>
          import("@/views/roles/super-admin/teambuilding/Details.vue"),
        meta: {
          pageTitle: "Team Building",
          breadcrumbs: ["Admin", "Team Building", "Detalles"],
        },
      },
    ],
  },
];
