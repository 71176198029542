export default [
  {
    path: "/master/announcements",
    children: [
      {
        path: "/master/announcements/",
        name: "announcements-index",
        component: () => import("@/views/roles/master/announcements/Index.vue"),
        meta: {
          pageTitle: "Anuncios",
          breadcrumbs: ["Admin", "Anuncios"],
        },
      },
      {
        path: "/master/announcements/create",
        name: "announcements-create",
        component: () =>
          import("@/views/roles/master/announcements/Create.vue"),
        meta: {
          pageTitle: "Anuncios",
          breadcrumbs: ["Admin", "Anuncios", "Create"],
        },
      },
      {
        path: "/master/announcements/edit/:id?",
        name: "announcements-edit",
        component: () => import("@/views/roles/master/announcements/Edit.vue"),
        meta: {
          pageTitle: "Anuncios",
          breadcrumbs: ["Admin", "Anuncios", "Edit"],
        },
      },
    ],
  },
];
