import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_action_button = _resolveComponent("action-button")!

  return (_openBlock(), _createBlock(_component_action_button, {
    tooltip: _ctx.tooltip ?? 'Copiar ID',
    icon: _ctx.icon ?? 'fa-regular fa-copy',
    onAction: _ctx.onAction
  }, null, 8, ["tooltip", "icon", "onAction"]))
}