export default [
  {
    path: "/employee/events",
    children: [
      {
        path: "/employee/events/",
        name: "events-eployee-index",
        component: () => import("@/views/roles/employee/events/Index.vue"),
        meta: {
          pageTitle: "Eventos",
          breadcrumbs: ["Empleados", "Eventos"],
        },
      },
      {
        path: "/employee/events/details/:id?",
        name: "events-employee-detail",
        component: () => import("@/views/roles/employee/events/Details.vue"),
        meta: {
          pageTitle: "Eventos",
          breadcrumbs: ["Empleados", "Eventos", "Detalles"],
        },
      },
    ],
  },
];
