export default [
  {
    path: "/superadmin/restaurants",
    children: [
      {
        path: "/superadmin/restaurants/",
        name: "restaurants-company-index",
        component: () =>
          import("@/views/roles/super-admin/restaurants/Index.vue"),
        meta: {
          pageTitle: "Restaurantes",
          breadcrumbs: ["Admin", "Restaurantes"],
        },
      },
      {
        path: "/superadmin/restaurants/create",
        name: "restaurants-company-create",
        component: () =>
          import("@/views/roles/super-admin/restaurants/Create.vue"),
        meta: {
          pageTitle: "Restaurantes",
          breadcrumbs: ["Admin", "Restaurantes", "Create"],
        },
      },
      {
        path: "/superadmin/restaurants/details/:id?",
        name: "restaurants-company-details",
        component: () =>
          import("@/views/roles/super-admin/restaurants/Details.vue"),
        meta: {
          pageTitle: "Restaurantes",
          breadcrumbs: ["Admin", "Restaurantes", "Detalles"],
        },
      },
      {
        path: "/superadmin/restaurants/deal/:id?",
        name: "restaurants-company-deal",
        component: () =>
          import("@/views/roles/super-admin/restaurants/Deals.vue"),
        meta: {
          pageTitle: "Restaurantes",
          breadcrumbs: ["Admin", "Restaurantes", "Configuración"],
        },
      },
    ],
  },
  {
    path: "/superadmin/redemptions",
    children: [
      {
        path: "/superadmin/redemptions/",
        name: "company-redemptions-index",
        component: () =>
          import("@/views/roles/super-admin/redemptions/redemptions/Index.vue"),
        meta: {
          pageTitle: "Redenciones",
          breadcrumbs: ["Admin", "Redenciones"],
        },
      },
      {
        path: "/superadmin/redemptions/details/:id?",
        name: "company-redemptions-details",
        component: () =>
          import(
            "@/views/roles/super-admin/redemptions/redemptions/Detail.vue"
          ),
        meta: {
          pageTitle: "Redenciones",
          breadcrumbs: ["Admin", "Redenciones"],
        },
      },
      {
        path: "/superadmin/redemptions/config",
        name: "redemptions-config-index",
        component: () =>
          import("@/views/roles/super-admin/redemptions/config/Index.vue"),
        meta: {
          pageTitle: "Restricciones",
          breadcrumbs: ["Admin", "Restricciones"],
        },
      },
      {
        path: "/superadmin/redemptions/config/edit",
        name: "redemptions-config-edit",
        component: () =>
          import("@/views/roles/super-admin/redemptions/config/Edit.vue"),
        meta: {
          pageTitle: "Restricciones",
          breadcrumbs: ["Admin", "Restricciones", "Edit"],
        },
      },
      {
        path: "/superadmin/redemptions/cuts",
        name: "company-redemptions-cuts",
        component: () =>
          import(
            "@/views/roles/super-admin/redemptions/redemptions-cuts/Index.vue"
          ),
        meta: {
          pageTitle: "Redenciones",
          breadcrumbs: ["Admin", "Redenciones", "Cortes"],
        },
      },
      {
        path: "/superadmin/redemptions/cuts/details/:id?",
        name: "company-redemptions-cuts-details",
        component: () =>
          import(
            "@/views/roles/super-admin/redemptions/redemptions-cuts/Detail.vue"
          ),
        meta: {
          pageTitle: "Redenciones",
          breadcrumbs: ["Admin", "Redenciones", "Cortes"],
        },
      },
    ],
  },
];
