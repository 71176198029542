export default [
  {
    path: "/master/events",
    children: [
      {
        path: "/master/events/",
        name: "events-index",
        component: () => import("@/views/roles/master/events/events/Index.vue"),
        meta: {
          pageTitle: "Eventos",
          breadcrumbs: ["Admin", "Eventos"],
        },
      },
      {
        path: "/master/events/create",
        name: "events-create",
        component: () =>
          import("@/views/roles/master/events/events/Create.vue"),
        meta: {
          pageTitle: "Eventos",
          breadcrumbs: ["Admin", "Eventos", "Create"],
        },
      },
      {
        path: "/master/events/edit/:id?",
        name: "events-edit",
        component: () => import("@/views/roles/master/events/events/Edit.vue"),
        meta: {
          pageTitle: "Eventos",
          breadcrumbs: ["Admin", "Eventos", "Edit"],
        },
      },
      {
        path: "/master/events/categories",
        name: "event-categories-index",
        component: () =>
          import("@/views/roles/master/events/event-categories/Index.vue"),
        meta: {
          pageTitle: "Categorías de eventos",
          breadcrumbs: ["Admin", "Eventos", "Categorias"],
        },
      },
      {
        path: "/master/events/categories/create",
        name: "event-categories-create",
        component: () =>
          import("@/views/roles/master/events/event-categories/Create.vue"),
        meta: {
          pageTitle: "Categorías de eventos",
          breadcrumbs: ["Admin", "Eventos", "Categorias", "Create"],
        },
      },
      {
        path: "/master/events/categories/edit/:id?",
        name: "event-categories-edit",
        component: () =>
          import("@/views/roles/master/events/event-categories/Edit.vue"),
        meta: {
          pageTitle: "Categorías de eventos",
          breadcrumbs: ["Admin", "Eventos", "Categorias", "Edit"],
        },
      },
    ],
  },
];
