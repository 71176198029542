export default [
  {
    path: "/superadmin/attendances",
    children: [
      {
        path: "/superadmin/attendances/",
        name: "attendances-index",
        component: () =>
          import("@/views/roles/super-admin/attendances/records/Index.vue"),
        meta: {
          pageTitle: "Asistencias",
          breadcrumbs: ["Admin", "Asistencias"],
        },
      },
      {
        path: "/superadmin/attendances/pending",
        name: "attendances-pending",
        component: () =>
          import("@/views/roles/super-admin/attendances/records/Pending.vue"),
        meta: {
          pageTitle: "Asistencias",
          breadcrumbs: ["Admin", "Asistencias", "Inconsistencias"],
        },
      },
      {
        path: "/superadmin/attendances/create",
        name: "attendances-create",
        component: () =>
          import("@/views/roles/super-admin/attendances/records/Create.vue"),
        meta: {
          pageTitle: "Asistencias",
          breadcrumbs: ["Admin", "Asistencias", "Create"],
        },
      },
      {
        path: "/superadmin/attendances/edit/:id?",
        name: "attendances-edit",
        component: () =>
          import("@/views/roles/super-admin/attendances/records/Edit.vue"),
        meta: {
          pageTitle: "Asistencias",
          breadcrumbs: ["Admin", "Asistencias", "Edit"],
        },
      },
    ],
  },
  {
    path: "/superadmin/attendances/summary",
    children: [
      {
        path: "/superadmin/attendances/summary/",
        name: "attendances-summary-index",
        component: () =>
          import("@/views/roles/super-admin/attendances/summary/Index.vue"),
        meta: {
          pageTitle: "Asistencias",
          breadcrumbs: ["Admin", "Asistencias", "Resumen"],
        },
      },
      {
        path: "/superadmin/attendances/summary/details/:id?",
        name: "attendances-summary-details",
        component: () =>
          import("@/views/roles/super-admin/attendances/summary/Details.vue"),
        meta: {
          pageTitle: "Asistencias",
          breadcrumbs: ["Admin", "Asistencias", "Resumen", "Detalles"],
        },
      },
    ],
  },
  {
    path: "/superadmin/attendances/categories",
    children: [
      {
        path: "/superadmin/attendances/categories",
        name: "attendances-categories-index",
        component: () =>
          import("@/views/roles/super-admin/attendances/categories/Index.vue"),
        meta: {
          pageTitle: "Asistencias",
          breadcrumbs: ["Admin", "Asistencias", "Categorías"],
        },
      },
      {
        path: "/superadmin/attendances/categories/create",
        name: "attendances-categories-create",
        component: () =>
          import("@/views/roles/super-admin/attendances/categories/Create.vue"),
        meta: {
          pageTitle: "Asistencias",
          breadcrumbs: ["Admin", "Asistencias", "Categorías", "Create"],
        },
      },
      {
        path: "/superadmin/attendances/categories/edit/:id?",
        name: "attendances-categories-edit",
        component: () =>
          import("@/views/roles/super-admin/attendances/categories/Edit.vue"),
        meta: {
          pageTitle: "Asistencias",
          breadcrumbs: ["Admin", "Asistencias", "Categorías", "Edit"],
        },
      },
    ],
  },
  {
    path: "/superadmin/attendances/syncs",
    children: [
      {
        path: "/superadmin/attendances/syncs/",
        name: "attendances-syncs-index",
        component: () =>
          import("@/views/roles/super-admin/attendances/syncs/Index.vue"),
        meta: {
          pageTitle: "Sincronizaciones con sistema de asistencias",
          breadcrumbs: ["Admin", "RH", "Sincronizaciones"],
        },
      },
    ],
  },
];
