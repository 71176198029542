export default [
  {
    path: "/employee/surveys",
    children: [
      {
        path: "/employee/surveys/",
        name: "surveys-employee-index",
        component: () => import("@/views/roles/employee/surveys/Index.vue"),
        meta: {
          pageTitle: "Encuestas",
          breadcrumbs: ["Empleados", "Encuestas"],
        },
      },
      {
        path: "/employee/surveys/answer/:id?",
        name: "surveys-employee-answer",
        component: () => import("@/views/roles/employee/surveys/Answer.vue"),
        meta: {
          pageTitle: "Encuestas",
          breadcrumbs: ["Empleados", "Encuestas", "Detalles"],
        },
      },
    ],
  },
];
