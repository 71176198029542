export default [
  {
    path: "/superadmin/work-modalities",
    children: [
      {
        path: "/superadmin/work-modalities/",
        name: "work-modalities-index",
        component: () =>
          import(
            "@/views/roles/super-admin/employees/work-modalities/Index.vue"
          ),
        meta: {
          pageTitle: "Modalidad de trabajo",
          breadcrumbs: ["Admin", "Modalidad de trabajo"],
        },
      },
      {
        path: "/superadmin/work-modalities/create",
        name: "work-modalities-create",
        component: () =>
          import(
            "@/views/roles/super-admin/employees/work-modalities/Create.vue"
          ),
        meta: {
          pageTitle: "Modalidad de trabajo",
          breadcrumbs: ["Admin", "Modalidad de trabajo", "Create"],
        },
      },
      {
        path: "/superadmin/work-modalities/edit/:id?",
        name: "work-modalities-edit",
        component: () =>
          import(
            "@/views/roles/super-admin/employees/work-modalities/Edit.vue"
          ),
        meta: {
          pageTitle: "Modalidad de trabajo",
          breadcrumbs: ["Admin", "Modalidad de trabajo", "Edit"],
        },
      },
    ],
  },
];
