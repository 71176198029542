export default [
  {
    path: "/employee/calendar",
    children: [
      {
        path: "/employee/calendar/",
        name: "calendar-eployee-index",
        component: () => import("@/views/roles/employee/calendar/Index.vue"),
        meta: {
          pageTitle: "Calendario",
          breadcrumbs: ["Empleados", "Calendario"],
        },
      },
    ],
  },
];
