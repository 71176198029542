import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { authService } from "@/services/auth/auth.service";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AuthModel } from "@/models/auth/auth.model";
import { UserModel } from "@/models/user/user.model";
import { Nullable } from "@/core/helpers/customtypes";
import { CompanyModel } from "@/models/companies/company.model";
import { RoleModel } from "@/models/user/role.model";
import { RestaurantModel } from "@/models/restaurants/restaurant.model";
import { EmployeeModel } from "@/models/employees/employee.model";
import { RoleEnum } from "@/core/data/role.enum";

export interface UserAuthInfo {
  errors: unknown;
  employee: Nullable<EmployeeModel>;
  user: Nullable<UserModel>;
  isPinAuthenticated: boolean;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  employee: Nullable<EmployeeModel>;
  user = ((): Nullable<UserModel> => {
    const userJson = JSON.parse(localStorage.getItem("user") ?? "null");
    const initialUser = userJson;

    return initialUser;
  })();
  isPinAuthenticated = ((): boolean => {
    return sessionStorage.getItem("isPinAuthenticated") === "1";
  })();
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): Nullable<UserModel> {
    return this.user;
  }

  get currentEmployee(): Nullable<EmployeeModel> {
    return this.employee;
  }

  get currentRestaurant(): Nullable<RestaurantModel> {
    return this.user?.restaurants?.[0];
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Verify user pin authentication
   * @returns boolean
   */
  get isUserPinAuthenticated(): boolean {
    return this.isPinAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](user: AuthModel) {
    this.isAuthenticated = true;
    this.user = user.user;
    this.errors = {};
    JwtService.saveToken(user.access_token);
    localStorage.setItem("user", JSON.stringify(this.user));
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_EMPLOYEE](employee) {
    this.employee = employee;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as UserModel;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Mutation
  [Mutations.SET_PIN_ACCESS](value: boolean) {
    this.isPinAuthenticated = value;
    sessionStorage.setItem(
      "isPinAuthenticated",
      (+this.isPinAuthenticated).toString()
    );
  }

  @Action({ rawError: true })
  [Actions.LOGIN](credentials) {
    const { email, password } = credentials;
    return authService
      .login(email as string, password as string)
      .then((data: AuthModel) => {
        const initCompany = this.context.rootGetters.initCompany;
        if (initCompany) {
          const userCompany = data.user.companies.find(
            (company) => company.key === initCompany && company.roles?.length
          );

          if (!userCompany) {
            return this.context.commit(Mutations.SET_ERROR, [
              "No cuentas con acceso a esta empresa",
            ]);
          }
        }

        this.context.commit(Mutations.SET_AUTH, data);

        let role: Nullable<RoleModel> = data.user.roles?.[0];
        const company = !role
          ? data.user.companies.find(
              (company) => !initCompany || company.key === initCompany
            )
          : null;

        if (!role && company) {
          role = company.roles?.[0];
        }

        this.context.dispatch(
          Actions.SET_ROLE_CONFIG,
          { company, role },
          { root: true }
        );
      })
      .catch((err) => {
        this.context.commit(Mutations.SET_ERROR, err.message);
      });
  }

  @Action
  [Actions.LOGOUT]() {
    const isWhiteLabel = this.context.rootGetters.isWhiteLabel as boolean;
    const company = this.context.rootGetters.currentCompany as CompanyModel;

    this.context.commit(Mutations.PURGE_AUTH);
    this.context.commit(Mutations.SET_PIN_ACCESS, false);

    this.context.dispatch(Actions.SET_ROLE_CONFIG, {
      company: isWhiteLabel ? company : null,
      role: null,
    });
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action({ rawError: true })
  [Actions.VERIFY_AUTH]() {
    const accessToken = JwtService.getToken();
    if (accessToken) {
      authService
        .getUserInfo()
        .then((data: UserModel) => {
          this.context.commit(Mutations.SET_AUTH, {
            access_token: accessToken,
            user: data,
          });

          let currentCompany = this.context.getters
            .currentCompany as Nullable<CompanyModel>;

          const companyIsValid = data.companies?.some(
            (company) => company.id === currentCompany?.id
          );

          if (currentCompany != null && !companyIsValid) {
            currentCompany = data.companies[0];
          }

          let currentRole = this.context.getters
            .currentRole as Nullable<RoleModel>;

          const roles = currentCompany?.roles ?? data.roles;
          const roleIsValid = roles.some((role) => role.id === currentRole?.id);
          if (!roleIsValid) {
            currentRole = roles[0];
          }
          this.context.dispatch(
            Actions.SET_ROLE_CONFIG,
            { company: currentCompany, role: currentRole },
            { root: true }
          );
        })
        .catch((error) => {
          this.context.commit(Mutations.SET_ERROR, error.message);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action({ rawError: true })
  [Actions.UPDATE_EMPLOYEE_INFO]() {
    const currentRole = this.context.rootGetters.currentRole as RoleModel;

    if (currentRole?.key !== RoleEnum.EMPLOYEE) {
      this.context.commit(Mutations.SET_EMPLOYEE, null);
      return;
    }

    authService
      .getEmployeeInfo()
      .then((employee: EmployeeModel) => {
        this.context.commit(Mutations.SET_EMPLOYEE, employee);
      })
      .catch(() => {
        this.context.commit(Mutations.SET_EMPLOYEE, null);
      });
  }

  @Action
  [Actions.SET_PIN_ACCESS]() {
    this.context.commit(Mutations.SET_PIN_ACCESS, true);
  }
}
