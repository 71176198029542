import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import { initBugsnag } from "@/core/plugins/bugsnag";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import ActionButton from "@/components/buttons/ActionButton.vue";
import CopyActionButton from "@/components/buttons/CopyActionButton.vue";
import ConfirmActionButton from "@/components/buttons/ConfirmActionButton.vue";
import DeletectionButton from "@/components/buttons/DeleteActionButton.vue";

import VueQRCodeComponent from "vue-qrcode-component";
import VueGoogleMaps from "@fawmi/vue-google-maps";

import "@/core/plugins/prismjs";
const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);

initBugsnag(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.component("qr-code", VueQRCodeComponent);
app.component("action-button", ActionButton);
app.component("copy-button", CopyActionButton);
app.component("confirm-button", ConfirmActionButton);
app.component("delete-button", DeletectionButton);

app.use(VueGoogleMaps, {
  load: {
    v: 3.53,
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
    language: "es",
  },
});

app.mount("#app");
