export default [
  {
    path: "/master/partners",
    children: [
      {
        path: "/master/partners/",
        name: "partners-master-index",
        component: () =>
          import("@/views/roles/master/partners/partners/Index.vue"),
        meta: {
          pageTitle: "Partners",
          breadcrumbs: ["Admin", "Partners"],
        },
      },
      {
        path: "/master/partners/create",
        name: "partners-master-create",
        component: () =>
          import("@/views/roles/master/partners/partners/Create.vue"),
        meta: {
          pageTitle: "Partners",
          breadcrumbs: ["Admin", "Partners", "Create"],
        },
      },
      {
        path: "/master/partners/edit/:id?",
        name: "partners-master-edit",
        component: () =>
          import("@/views/roles/master/partners/partners/Edit.vue"),
        meta: {
          pageTitle: "Partners",
          breadcrumbs: ["Admin", "Partners", "Edit"],
        },
      },
    ],
  },
  {
    path: "/master/partners/categories",
    children: [
      {
        path: "/master/partners/categories",
        name: "partner-categories-index",
        component: () =>
          import("@/views/roles/master/partners/partner-categories/Index.vue"),
        meta: {
          pageTitle: "Partners",
          breadcrumbs: ["Admin", "Partners", "Categorias"],
        },
      },
      {
        path: "/master/partners/categories/create",
        name: "partner-categories-create",
        component: () =>
          import("@/views/roles/master/partners/partner-categories/Create.vue"),
        meta: {
          pageTitle: "Partners",
          breadcrumbs: ["Admin", "Partners", "Categorias", "Create"],
        },
      },
      {
        path: "/master/partners/categories/edit/:id?",
        name: "partner-categories-edit",
        component: () =>
          import("@/views/roles/master/partners/partner-categories/Edit.vue"),
        meta: {
          pageTitle: "Partners",
          breadcrumbs: ["Admin", "Partners", "Categorias", "Edit"],
        },
      },
    ],
  },
];
