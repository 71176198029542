export default [
  {
    path: "/superadmin/reports",
    children: [
      {
        path: "/superadmin/reports/:status?",
        name: "reports-index",
        component: () => import("@/views/roles/super-admin/reports/Index.vue"),
        meta: {
          pageTitle: "Reportes",
          breadcrumbs: ["Admin", "Reportes"],
        },
      },
      {
        path: "/superadmin/reports/details/:id?",
        name: "reports-details",
        component: () =>
          import("@/views/roles/super-admin/reports/Details.vue"),
        meta: {
          pageTitle: "Reportes",
          breadcrumbs: ["Admin", "Reportes", "Detalles"],
        },
      },
    ],
  },
];
