export default [
  {
    path: "/superadmin/questionsusers",
    children: [
      {
        path: "/superadmin/questionsusers/",
        name: "questionsusers-index",
        component: () =>
          import("@/views/roles/super-admin/help/questionsusers/Index.vue"),
        meta: {
          pageTitle: "Preguntas de usuarios",
          breadcrumbs: ["Admin", "Preguntas de usuarios"],
        },
      },

      {
        path: "/superadmin/questionsusers/details/:id?",
        name: "questionsusers-details",
        component: () =>
          import("@/views/roles/super-admin/help/questionsusers/Details.vue"),
        meta: {
          pageTitle: "Preguntas de usuarios",
          breadcrumbs: ["Admin", "Preguntas de usuarios", "Detalles"],
        },
      },
    ],
  },
];
