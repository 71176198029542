export default [
  {
    path: "/superadmin/internal-benefits",
    children: [
      {
        path: "/superadmin/internal-benefits",
        name: "internal-benefits-index",
        component: () =>
          import("@/views/roles/super-admin/internal-benefits/Index.vue"),
        meta: {
          pageTitle: "Beneficios Internos",
          breadcrumbs: ["Admin", "Beneficios Internos"],
        },
      },
      {
        path: "/superadmin/internal-benefits/create",
        name: "internal-benefits-create",
        component: () =>
          import("@/views/roles/super-admin/internal-benefits/Create.vue"),
        meta: {
          pageTitle: "Crear Beneficio Interno",
          breadcrumbs: ["Admin", "Beneficios Internos", "Crear"],
        },
      },
      {
        path: "/superadmin/internal-benefits/edit/:id?",
        name: "internal-benefits-edit",
        component: () =>
          import("@/views/roles/super-admin/internal-benefits/Edit.vue"),
        meta: {
          pageTitle: "Editar Beneficios Internal",
          breadcrumbs: ["Admin", "Beneficios Internos", "Editar"],
        },
      },
    ],
  },
];
