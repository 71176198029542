export default [
  {
    path: "/superadmin/positions",
    children: [
      {
        path: "/superadmin/positions/",
        name: "positions-index",
        component: () =>
          import("@/views/roles/super-admin/employees/positions/Index.vue"),
        meta: {
          pageTitle: "Cargos",
          breadcrumbs: ["Admin", "Cargos"],
        },
      },
      {
        path: "/superadmin/positions/create",
        name: "positions-create",
        component: () =>
          import("@/views/roles/super-admin/employees/positions/Create.vue"),
        meta: {
          pageTitle: "Cargos",
          breadcrumbs: ["Admin", "Cargos", "Create"],
        },
      },
      {
        path: "/superadmin/positions/edit/:id?",
        name: "positions-edit",
        component: () =>
          import("@/views/roles/super-admin/employees/positions/Edit.vue"),
        meta: {
          pageTitle: "Cargos",
          breadcrumbs: ["Admin", "Cargos", "Edit"],
        },
      },
    ],
  },
];
