export default [
  {
    path: "/master/config",
    children: [
      {
        path: "/master/config/",
        name: "master-config-index",
        component: () => import("@/views/roles/master/admin/config/Index.vue"),
        meta: {
          pageTitle: "Configuración general",
          breadcrumbs: ["Admin", "Configuración"],
        },
      },
      {
        path: "/master/config/edit",
        name: "master-config-edit",
        component: () => import("@/views/roles/master/admin/config/Edit.vue"),
        meta: {
          pageTitle: "Usuarios Nuup",
          breadcrumbs: ["Admin", "Configuración", "Edit"],
        },
      },
    ],
  },
];
