export default [
  {
    path: "/superadmin/benefits/config",
    children: [
      {
        path: "/superadmin/benefits/config/",
        name: "benefits-category-config",
        component: () =>
          import(
            "@/views/roles/super-admin/benefits/benefit-categories/Index.vue"
          ),
        meta: {
          pageTitle: "Beneficios",
          breadcrumbs: ["Admin", "Beneficios"],
        },
      },
      {
        path: "/superadmin/benefits/config/:id?/",
        name: "benefits-config",
        component: () =>
          import(
            "@/views/roles/super-admin/benefits/benefit-categories/Details.vue"
          ),
        meta: {
          pageTitle: "Beneficios",
          breadcrumbs: ["Admin", "Beneficios", "Detalles"],
        },
      },
      {
        path: "/superadmin/benefits/config/:id?/details",
        name: "benefits-details",
        component: () =>
          import("@/views/roles/super-admin/benefits/benefits/Details.vue"),
        meta: {
          pageTitle: "Beneficios",
          breadcrumbs: ["Admin", "Beneficios", "Detalles"],
        },
      },
    ],
  },
];
