import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Nullable } from "@/core/helpers/customtypes";
import { CompanyModel } from "@/models/companies/company.model";
import { RoleModel } from "@/models/user/role.model";
import { companiesService } from "@/services/companies/companies.service";
import router from "@/router";

export interface RoleInfo {
  initCompanyKey: Nullable<string>;
  company: Nullable<CompanyModel>;
  role: Nullable<RoleModel>;
}

@Module
export default class RoleModule extends VuexModule implements RoleInfo {
  initCompanyKey: Nullable<string> = (() => {
    const envCompany = process.env.VUE_APP_COMPANY_KEY;
    if (!envCompany) return null;

    return envCompany as string;
  })();

  company = ((): Nullable<CompanyModel> =>
    JSON.parse(localStorage.getItem("company") ?? "null"))();

  role = ((): Nullable<RoleModel> =>
    JSON.parse(localStorage.getItem("role") ?? "null"))();

  get isWhiteLabel(): boolean {
    return !!this.initCompanyKey;
  }

  get initCompany(): Nullable<string> {
    return this.initCompanyKey;
  }

  get currentCompany(): Nullable<CompanyModel> {
    return this.company;
  }

  get currentRole(): Nullable<RoleModel> {
    return this.role;
  }

  @Mutation
  [Mutations.SET_COMPANY](company: Nullable<CompanyModel>) {
    this.company = company ?? null;
    localStorage.setItem("company", JSON.stringify(this.company));
  }

  @Mutation
  [Mutations.SET_ROLE](role: Nullable<RoleModel>) {
    this.role = role ?? null;
    localStorage.setItem("role", JSON.stringify(this.role));
  }

  @Action
  [Actions.SET_ROLE_CONFIG]({
    company,
    role,
  }: {
    company: Nullable<CompanyModel>;
    role: Nullable<RoleModel>;
  }) {
    this.context.commit(Mutations.SET_COMPANY, company);
    this.context.commit(Mutations.SET_ROLE, role);
  }

  @Action
  [Actions.INIT_COMPANY]() {
    if (!this.initCompanyKey) {
      return Promise.resolve(null);
    }

    const companyIsDiferent =
      this.company && this.company.key !== this.initCompanyKey;

    if (!this.company || companyIsDiferent) {
      return companiesService
        .getByKey(this.initCompanyKey)
        .then((company) => {
          this.context.commit(Mutations.SET_COMPANY, company);
          this.context.commit(Mutations.SET_ROLE, null);

          if (companyIsDiferent) {
            this.context.commit(Mutations.PURGE_AUTH);
            this.context.commit(Mutations.SET_PIN_ACCESS, false);
            router.push("/");
          }

          return company;
        })
        .catch(() => {
          // ignored
        });
    }
  }
}
