import { Dictionary, Nullable } from "../helpers/customtypes";

export class Api {
  private static readonly API_CENTRAL = process.env.VUE_APP_API_CENTRAL_URL;
  private static readonly API_COMPANY = process.env.VUE_APP_API_COMPANY_URL;

  private static fillPathParams(
    url: string,
    params?: Dictionary<Nullable<string | number | string>>
  ): string {
    if (!params) return url;
    return Object.keys(params).reduce((acc, item) => {
      const param = params[item];
      if (!param) return acc;

      const value = params[item] ?? "";
      return acc.replace(`{${item}}`, `${value}`);
    }, url);
  }

  public static getCentralServiceUrl(
    service: string,
    params?: Dictionary<Nullable<string | number | string>>
  ): string {
    return Api.fillPathParams(`${Api.API_CENTRAL}${service}`, params);
  }

  public static getCompanyServiceUrl(
    service: string,
    params?: Dictionary<Nullable<string | number | string>>
  ): string {
    return Api.fillPathParams(`${Api.API_COMPANY}${service}`, params);
  }
}
