export default [
  {
    path: "/master/benefits",
    children: [
      {
        path: "/master/benefits/",
        name: "benefits-index",
        component: () =>
          import("@/views/roles/master/benefits/benefits/Index.vue"),
        meta: {
          pageTitle: "Beneficios",
          breadcrumbs: ["Admin", "benefits"],
        },
      },
      {
        path: "/master/benefits/pending",
        name: "benefits-pending",
        component: () =>
          import("@/views/roles/master/benefits/benefits/Pending.vue"),
        meta: {
          pageTitle: "Beneficios pendientes de configuración",
          breadcrumbs: ["Admin", "Beneficios"],
        },
      },
      {
        path: "/master/benefits/create",
        name: "benefits-create",
        component: () =>
          import("@/views/roles/master/benefits/benefits/Create.vue"),
        meta: {
          pageTitle: "Beneficios",
          breadcrumbs: ["Admin", "Beneficios", "Create"],
        },
      },
      {
        path: "/master/benefits/edit/:id?",
        name: "benefits-edit",
        component: () =>
          import("@/views/roles/master/benefits/benefits/Edit.vue"),
        meta: {
          pageTitle: "Beneficios",
          breadcrumbs: ["Admin", "Beneficios", "Edit"],
        },
      },
      {
        path: "/master/benefits/details/:id?",
        name: "benefits-detail",
        component: () =>
          import("@/views/roles/master/benefits/benefits/Details.vue"),
        meta: {
          pageTitle: "Beneficios",
          breadcrumbs: ["Admin", "Beneficios", "Detalles"],
        },
      },
      {
        path: "/master/benefits/categories",
        name: "benefit-categories-index",
        component: () =>
          import("@/views/roles/master/benefits/benefit-categories/Index.vue"),
        meta: {
          pageTitle: "Categorías de beneficios",
          breadcrumbs: ["Admin", "Beneficios", "Categorias"],
        },
      },
      {
        path: "/master/benefits/categories/create",
        name: "benefit-categories-create",
        component: () =>
          import("@/views/roles/master/benefits/benefit-categories/Create.vue"),
        meta: {
          pageTitle: "Categorías de beneficios",
          breadcrumbs: ["Admin", "Beneficios", "Categorias", "Create"],
        },
      },
      {
        path: "/master/benefits/categories/edit/:id?",
        name: "benefit-categories-edit",
        component: () =>
          import("@/views/roles/master/benefits/benefit-categories/Edit.vue"),
        meta: {
          pageTitle: "Categorías de beneficios",
          breadcrumbs: ["Admin", "Beneficios", "Categorias", "Edit"],
        },
      },
      {
        path: "/master/benefits/keywords",
        name: "benefit-keywords-index",
        component: () =>
          import("@/views/roles/master/benefits/benefit-keywords/Index.vue"),
        meta: {
          pageTitle: "Palabras clave",
          breadcrumbs: ["Admin", "Beneficios", "Categorias"],
        },
      },
      {
        path: "/master/benefits/keywords/create",
        name: "benefit-keywords-create",
        component: () =>
          import("@/views/roles/master/benefits/benefit-keywords/Create.vue"),
        meta: {
          pageTitle: "Palabras clave",
          breadcrumbs: ["Admin", "Beneficios", "Palabras clave", "Create"],
        },
      },
      {
        path: "/master/benefits/keywords/edit/:id?",
        name: "benefit-keywords-edit",
        component: () =>
          import("@/views/roles/master/benefits/benefit-keywords/Edit.vue"),
        meta: {
          pageTitle: "Palabras clave",
          breadcrumbs: ["Admin", "Beneficios", "Palabras clave", "Edit"],
        },
      },
    ],
  },
  {
    path: "/master/providers",
    children: [
      {
        path: "/master/providers/",
        name: "providers-index",
        component: () => import("@/views/roles/master/providers/Index.vue"),
        meta: {
          pageTitle: "Proveedores",
          breadcrumbs: ["Admin", "Proveedores"],
        },
      },
      {
        path: "/master/providers/create",
        name: "providers-create",
        component: () => import("@/views/roles/master/providers/Create.vue"),
        meta: {
          pageTitle: "Proveedores",
          breadcrumbs: ["Admin", "Proveedores", "Create"],
        },
      },
      {
        path: "/master/providers/edit/:id?",
        name: "providers-edit",
        component: () => import("@/views/roles/master/providers/Edit.vue"),
        meta: {
          pageTitle: "Proveedores",
          breadcrumbs: ["Admin", "Proveedores", "Edit"],
        },
      },
    ],
  },
  {
    path: "/master/benefit-syncs",
    children: [
      {
        path: "/master/benefit-syncs/",
        name: "benefit-syncs-index",
        component: () =>
          import("@/views/roles/master/benefits/benefit-syncs/Index.vue"),
        meta: {
          pageTitle: "Sincronizaciones de beneficios",
          breadcrumbs: ["Admin", "Beneficios", "Sincronizaciones"],
        },
      },
    ],
  },
];
