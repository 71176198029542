export default [
  {
    path: "/superadmin/surveys",
    children: [
      {
        path: "/superadmin/surveys/",
        name: "surveys-index",
        component: () => import("@/views/roles/super-admin/surveys/Index.vue"),
        meta: {
          pageTitle: "Encuestas",
          breadcrumbs: ["Admin", "Encuestas"],
        },
      },
      {
        path: "/superadmin/surveys/create",
        name: "surveys-create",
        component: () => import("@/views/roles/super-admin/surveys/Create.vue"),
        meta: {
          pageTitle: "Encuestas",
          breadcrumbs: ["Admin", "Encuestas", "Create"],
        },
      },
      {
        path: "/superadmin/surveys/edit/:id?",
        name: "surveys-edit",
        component: () => import("@/views/roles/super-admin/surveys/Edit.vue"),
        meta: {
          pageTitle: "Encuestas",
          breadcrumbs: ["Admin", "Encuestas", "Edit"],
        },
      },
      {
        path: "/superadmin/surveys/details/:id?",
        name: "surveys-details",
        component: () =>
          import("@/views/roles/super-admin/surveys/Details.vue"),
        meta: {
          pageTitle: "Encuestas",
          breadcrumbs: ["Admin", "Encuestas", "Detalles"],
        },
      },
      {
        path: "/superadmin/surveys/details/:surveyId/answers/:id",
        name: "surveys-question-answers",
        component: () =>
          import("@/views/roles/super-admin/surveys/Answers.vue"),
        meta: {
          pageTitle: "Encuestas",
          breadcrumbs: ["Admin", "Encuestas", "Preguntas", "Respuestas"],
        },
      },
    ],
  },
];
